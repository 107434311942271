import type { Ref } from 'vue'
import { nextTick, ref } from 'vue'
import {
  type GeoJSONSourceRaw,
  LngLatBounds,
  Map,
  type Map as MapType,
  Popup,
  type Popup as PopupType,
} from 'mapbox-gl'
import {

} from 'mapbox-gl'
import { type ExtraOption, type MapPoints, type UiMapOptions } from './UiMapContainer.types'

export function useUiMap(container: Ref<HTMLElement | null>) {
  const map = ref<MapType | null>(null)
  const popup = ref<PopupType | null>(null)

  async function initMap(options: UiMapOptions) {
    await nextTick()

    map.value = new Map({
      container: container.value, // container ID
      style: options.styleToken ?? 'mapbox://styles/mapbox/streets-v12', // style URL
      center: options.center ?? [17.099, 51.129], // starting position [lng, lat], deafult poland center
      zoom: options.zoom ?? 9, // starting zoom
      accessToken: options.accessToken ?? '',
      cooperativeGestures: true, // disable touch zoom
      interactive: options.interactive ?? true, // user interaction
    })

    if (options.extraOptions) {
      initOptions(options)
    }

    if (options.points && map.value) {
      map.value.on('load', () => {
        addPoints(map.value, popup.value, options)
      })
    }
  }

  function initOptions(options: UiMapOptions) {
    options.extraOptions.forEach((extraOption: ExtraOption) => {
      extraOption(map.value)
    })
  }

  interface templatesType {
    name: string
    style: {
      maxWidth: string
    }
    html: string
  }

  function mainTemplate(name: string, place: string, city: string) {
    return `
      <p class="text-primary text-sm">
        <span class="font-bold block">${name}</span>
        <span class="block">${place}, ${city}</span>
      </p>
    `
  }

  function templateWithCount(count: string) {
    return `
      <p class="text-base text-primary border-b border-primary mb-1">W tej lokalizacji zorganizowaliśmy <span class="font-medium">${count}</span> szkoleń</p>
      <p class="text-sm">Ostatnie szkolenie:</p>
      ${mainTemplate}
    `
  }

  function templateWithImg(name: string, place: string, city: string, img: string) {
    return `
      <img src="${img}" alt="Placeholder image" class="h-[150px] object-cover rounded-t-xl">
      <p class="text-primary text-sm p-5">
        <span class="font-bold block">${name}</span>
        <span class="block"><b>${place}</b>, ${city}</span>
      </p>
    `
  }

  const markerImages = [
    {
      name: 'map-point-orange-with-logo',
      path: publicDir('/maps/map-point-orange-with-logo.png'),
    },
    {
      name: 'map-point-blue-with-logo',
      path: publicDir('/maps/map-point-blue-with-logo.png'),
    },
    {
      name: 'map-point-orange-without-logo',
      path: publicDir('/maps/map-point-orange-without-logo.png'),
    },
  ]

  function boundsExtend(bounds: LngLatBounds, features: any) {
    features.forEach((feature) => {
      bounds.extend(feature.geometry.coordinates)
    })
  }

  function createDataSource(points: MapPoints[]) {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: points,
      },
      // cluster: true,
      // clusterMaxZoom: 14, // Max zoom to cluster points
      // clusterRadius: 3, // Radius of each cluster when clustering points
    }
  }

  function addSource(map: mapboxgl.Map, sourceName: string, dataSource: GeoJSONSourceRaw) {
    map.addSource(sourceName, dataSource)
  }

  function addCircleCluster(map: mapboxgl.Map, sourceName: string, color?: string) {
    map.addLayer({
      id: `clusters-${sourceName}`,
      type: 'circle',
      source: sourceName,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': color || '#51bbd6',
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          10, // small cluster radius
          30, // medium cluster radius
          50, // large cluster radius
        ],
      },
    })
  }

  function addClusterCount(map: mapboxgl.Map, sourceName: string) {
    map.addLayer({
      id: `cluster-count-${sourceName}`,
      type: 'symbol',
      source: sourceName,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 14,
      },
      paint: {
        'text-color': '#fff',
      },
    })
  }

  function addUnclusteredPoints(map: mapboxgl.Map, sourceName: string) {
    map.addLayer({
      id: `unclustered-point-${sourceName}`,
      type: 'symbol', // Change type to 'symbol' to use icons
      source: sourceName,
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': sourceName,
        'icon-size': 1, // Adjust the size of the icon as needed
        'icon-allow-overlap': true, // Allow icons to overlap
        'text-field': [
          'case',
          ['has', 'count'], // Check if 'count' property exists
          [
            'case',
            ['>', ['to-number', ['get', 'count']], 999], // Check if 'count' is greater than 999
            ['concat', '> ', '999'], // Display "> 999" if greater
            ['get', 'count'], // Display 'count' value if not greater
          ],
          '', // Display an empty string if 'count' does not exist
        ],
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-size': 12, // Adjust the font size of the text
        'text-anchor': 'center', // Position the text at the top of the icon
        'text-offset': [0, -0.25], // Offset the text position if needed
        'text-allow-overlap': true,
      },
      paint: {
        'text-color': '#fff',
      },
    })
  }

  // Na ten moment klastry nie są używane, ale zostawiamy tę funkcję na przyszłość
  //
  // const onClusterClick = (e: any , map:mapboxgl.Map, sourceName: string) => {
  //   if (e && e.point) {
  //     const features = map?.queryRenderedFeatures(e.point, {
  //       layers: ['clusters'],
  //     })

  //     const cluster = features[0]
  //     const clusterId = cluster.properties.cluster_id

  //     // Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.

  //     const source = map.getSource(sourceName) as GeoJSONSource

  //     source.getClusterExpansionZoom(clusterId, (err, zoom) => {
  //       if (!err) {
  //         map.easeTo({
  //           center: cluster.geometry.coordinates,
  //           zoom,
  //         })
  //       }
  //     })
  //   }
  // }

  function onPointClick(e, map, popup: Popup) {
    const coordinates = e.features[0].geometry.coordinates.slice()
    const name = e.features[0].properties?.name
    const place = e.features[0].properties?.place
    const city = e.features[0].properties?.city
    const count = e.features[0].properties?.count ?? null
    const img = e.features[0].properties?.img ?? null

    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
    }

    function getTemplateName() {
      if (img) {
        return 'templateWithImg'
      }
      else if (count) {
        return 'templateWithCount'
      }
      else {
        return 'mainTemplate'
      }
    }

    const templates: templatesType[] = [
      {
        name: 'mainTemplate',
        style: {
          maxWidth: '300px',
        },
        html: mainTemplate(name, place, city),
      },
      {
        name: 'templateWithCount',
        style: {
          maxWidth: '300px',
        },
        html: templateWithCount(count),
      },
      {
        name: 'templateWithImg',
        style: {
          maxWidth: '300px',
        },
        html: templateWithImg(name, place, city, img),
      },
    ]

    const currentTemplate = templates.find(el => el.name === getTemplateName())

    if (popup) {
      popup
        .setLngLat(coordinates)
        .setHTML(currentTemplate.html)
        .setMaxWidth(currentTemplate.style.maxWidth)
        .addTo(map)
    }
  }

  function addPoints(map: mapboxgl.Map, popup: Popup, options: UiMapOptions) {
    if (!map) {
      return
    }

    const bounds = new LngLatBounds()

    // Load marker images to map
    markerImages.forEach((item) => {
      const { name, path } = item
      map.loadImage(path, (error, image) => {
        if (error) { throw error }
        map.addImage(name, image)
      })
    })

    // Add sources and layers based on props
    if (options?.points) {
      for (const [key, value] of Object.entries(options.points)) {
        const source = createDataSource(value)
        boundsExtend(bounds, source.data.features)
        addSource(map, key, source as GeoJSONSourceRaw)
        addCircleCluster(map, key, options?.circleClusterColor)
        addClusterCount(map, key)
        addUnclusteredPoints(map, key)

        // Add click event to unclustered points
        map.on('click', `unclustered-point-${key}`, event => onPointClick(event, map, popup))
      }
    }

    if (popup) {
      popup = new Popup({
        closeButton: false,
      })
    }

    /* // START Set map center and zoom to fit the bounds

      dataSource.value.data.features.forEach((feature) => {
        bounds.extend(feature.geometry.coordinates)
      })

      map.fitBounds(bounds, {
        padding: 100, // Adjust the padding around the bounds
        maxZoom: 14, // Limit max zoom level when fitting bounds
      })
      // END Set map center and zoom to fit the bounds
      map.on('click', 'clusters', e => onClusterClick(e, map, 'pointsInMap'))
    */
  }

  return {
    initMap,
  }
}
