import type {
  CustomerAccessRule,
  CustomerAction,
  CustomerSubject,
} from '../types/acl'
import { abilities } from '../plugins/acl'
import type { CustomerDataAcl } from '../types/customerSectionData'
import type { CustomerRoutes } from '../types/routes'

interface CaslRule {
  action: CustomerAction
  subject: CustomerSubject
}

export function useCustomerAcl() {
  /**
   * Update abiliites based on logged in customer and his ACL
   * @param customerAcl
   */
  const updateAbility = (customerAcl?: CustomerDataAcl) => {
    if (!customerAcl) {
      return abilities.update([])
    }
    const prepared = prepareCustomerAcl(customerAcl)
    if (!prepared) {
      return
    }

    abilities.update(prepared)
  }

  /**
   * Check if customer has access for all required rules
   * @example [['read','graphl_company']]
   * @param rules
   */
  const hasAccessTo = (rules: CustomerAccessRule[]) => {
    if (!Array.isArray(rules)) {
      console.warn('Wrong rules: CustomerAccessRule[] type')
      return false
    }

    const canAccessAll = rules.every(([action, subject]) =>
      abilities.can(action, subject),
    )
    return canAccessAll
  }

  /**
   * Check if customer has access to selected route
   * Page Meta requires: {acces?, name} to be findable
   * @param routeName
   */
  const hasAccessToRoute = (routeName: CustomerRoutes | string) => {
    const { getRoutes } = useRouter()
    const route = getRoutes().find(({ name }) => name === routeName)
    if (!route || !route.meta.access) {
      return true
    }

    return hasAccessTo(route.meta.access)
  }

  /**
   * Scan customer section acl and find single or multiple abilities
   * single ability eg: "allow_all"
   * multiple abilities eg: { permission_code: string; access_type: string; }
  }>
   * @param customerAcl
   */
  const prepareCustomerAcl = (customerAcl: CustomerDataAcl) => {
    if (!customerAcl) {
      return null
    }

    const allowAll = customerAcl.permissions.find(
      ability => ability === 'allow_all',
    )

    if (allowAll) {
      return [
        {
          action: 'manage',
          subject: 'all',
        },
      ]
    }

    return mapPermissions(customerAcl.permissions)
  }

  /**
   * Map customer section acl permission to CaslRule
   * @param permissions
   */
  const mapPermissions = (
    permissions: CustomerDataAcl['permissions'],
  ): CaslRule[] => {
    const result: CaslRule[] = []

    permissions.forEach((permission) => {
      if (typeof permission === 'string') {
        return
      }

      const actions = permission.access_type.split('|') as CustomerAction[]
      actions.forEach((action) => {
        result.push({
          subject: permission.permission_code,
          action,
        })
      })
    })

    return result
  }

  return {
    updateAbility,
    hasAccessTo,
    hasAccessToRoute,
  }
}
