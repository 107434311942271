export const delayHydrationOptions = {
  "hydrateOnEvents": [
    "mousemove",
    "scroll",
    "keydown",
    "click",
    "touchstart",
    "wheel"
  ],
  "idleCallbackTimeout": 8000,
  "postIdleTimeout": 4000
}
