interface Client {
  name: string
  img: string
}

const elements: Client[] = [
  {
    name: 'Atlas',
    img: '/about-us-ldp/our-clients/atlas.png',
  },
  {
    name: 'Baumit',
    img: '/about-us-ldp/our-clients/baumit.png',
  },
  {
    name: 'Bolix',
    img: '/about-us-ldp/our-clients/bolix.png',
  },
  {
    name: 'Brico',
    img: '/about-us-ldp/our-clients/brico.png',
  },
  {
    name: 'Budimex',
    img: '/about-us-ldp/our-clients/budimex.png',
  },
  {
    name: 'Caparol',
    img: '/about-us-ldp/our-clients/caparol.png',
  },
  {
    name: 'Castorama',
    img: '/about-us-ldp/our-clients/castorama.png',
  },
  {
    name: 'Cemix',
    img: '/about-us-ldp/our-clients/cemix.png',
  },
  {
    name: 'Ceresit',
    img: '/about-us-ldp/our-clients/ceresit.png',
  },
  {
    name: 'Fatra',
    img: '/about-us-ldp/our-clients/fatra.png',
  },
  {
    name: 'Firestone',
    img: '/about-us-ldp/our-clients/firestone.png',
  },
  {
    name: 'Holcim',
    img: '/about-us-ldp/our-clients/holcim.png',
  },
  {
    name: 'Isover',
    img: '/about-us-ldp/our-clients/isover.png',
  },
  {
    name: 'Knauf',
    img: '/about-us-ldp/our-clients/knauf.png',
  },
  {
    name: 'Lafarge',
    img: '/about-us-ldp/our-clients/lafarge.png',
  },
  {
    name: 'Leroy',
    img: '/about-us-ldp/our-clients/leroy.png',
  },
  {
    name: 'Mapei',
    img: '/about-us-ldp/our-clients/mapei.png',
  },
  {
    name: 'Monta Engil',
    img: '/about-us-ldp/our-clients/monta-engil.png',
  },
  {
    name: 'Obi',
    img: '/about-us-ldp/our-clients/obi.png',
  },
  {
    name: 'Ppg',
    img: '/about-us-ldp/our-clients/ppg.png',
  },
  {
    name: 'Prb',
    img: '/about-us-ldp/our-clients/prb.png',
  },
  {
    name: 'Pri',
    img: '/about-us-ldp/our-clients/pri.png',
  },
  {
    name: 'Protan',
    img: '/about-us-ldp/our-clients/protan.png',
  },
  {
    name: 'Psb',
    img: '/about-us-ldp/our-clients/psb.png',
  },
  {
    name: 'Rockwool',
    img: '/about-us-ldp/our-clients/rockwool.png',
  },
  {
    name: 'Selena',
    img: '/about-us-ldp/our-clients/selena.png',
  },
  {
    name: 'Sig',
    img: '/about-us-ldp/our-clients/sig.png',
  },
  {
    name: 'Sika',
    img: '/about-us-ldp/our-clients/sika.png',
  },
  {
    name: 'Sopro',
    img: '/about-us-ldp/our-clients/sopro.png',
  },
  {
    name: 'Tim',
    img: '/about-us-ldp/our-clients/tim.png',
  },
  {
    name: 'warbud',
    img: '/about-us-ldp/our-clients/warbud.png',
  },
]

export default elements
