import { default as _91_46_46_46slug_9329HLXATezaMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as addressesVB90J9zelXMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companyt9X90fGG1CMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contacteilPxfGoTHMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexiCa325JIHpMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as listsUM9SpOpz7bMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91_46_46_46slug_93cjBuaGY3GOMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as ordersi1MOLZhnrfMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billing9U44AIN8KYMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45userlklZNjHTiyMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editmmLz0byqEcMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as index5SeZvSWg6gMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as new5OsPmRxY6hMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as editqjYNxF2bOlMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexgNhOd8u8PGMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profilehhUY4X7F1SMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountKQWfP6MdTtMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93VuFTFINNzmMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as categoryu2S09khqW5Meta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cartwzTjo1WrOMMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45paymentDPgxC1R0kQMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summaryZr8lTMhmegMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkout5MJfqi0C77Meta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPassword6DXfAI9zVWMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordiyvJOBoubVMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexShtsmhi1yTMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indextTCDIxKcl5Meta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productrKIhDwSUlAMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as registergy026HR5dsMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolvekwyBSmcbg5Meta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cms2DnjOQxIB2Meta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as store526tipTctMMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchuOiKTJMX1tMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectionqPs3QSmwQVMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSectiongtE4B98e6zMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexKL9kXBDFJJMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClienteV948mQl05Meta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElementcVIo6rbDIHMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElementAuFmftqXL7Meta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSectionuKhgvMQZHFMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeaderYU1loUEtIBMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionj4Gc2QAcQQMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugh2pCsps1HsMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormPEyCDd9xWwMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModaltnY2uYinLFMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugSticky6HPssqrnFjMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCtaLXdXrKmafhMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeftaKUFM45C7VMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightm68Cl0anf8Meta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPaginationG5HfVBLoRcMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionWp0Iv6sYzlMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidesrQa5FlikOjMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSection0P7OZdVOLVMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePaginationfwRXFP67YIMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightiRTJJ7RqNJMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as indexmmtynXHkWrMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constants1OqslcmOxjMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPageXBt4NGlZAVMeta } from "/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PageA8HtCIDE3OMeta } from "/home/ecomadm/app/releases/58/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_9329HLXATezaMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-cms/pages/[...slug].vue")
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountKQWfP6MdTtMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account.vue"),
    children: [
  {
    name: addressesVB90J9zelXMeta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesVB90J9zelXMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/addresses.vue")
  },
  {
    name: companyt9X90fGG1CMeta?.name ?? "locale-account-company",
    path: "company",
    meta: companyt9X90fGG1CMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/company.vue")
  },
  {
    name: contacteilPxfGoTHMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contacteilPxfGoTHMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/contact.vue")
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/index.vue")
  },
  {
    name: listsUM9SpOpz7bMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: listsUM9SpOpz7bMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/lists.vue")
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    meta: _91_46_46_46slug_93cjBuaGY3GOMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue")
  },
  {
    name: ordersi1MOLZhnrfMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: ordersi1MOLZhnrfMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/orders.vue")
  },
  {
    name: payments_45and_45billing9U44AIN8KYMeta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billing9U44AIN8KYMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue")
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45userlklZNjHTiyMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue")
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editmmLz0byqEcMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue")
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: index5SeZvSWg6gMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue")
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: new5OsPmRxY6hMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue")
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: editqjYNxF2bOlMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue")
  },
  {
    name: indexgNhOd8u8PGMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexgNhOd8u8PGMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue")
  },
  {
    name: profilehhUY4X7F1SMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profilehhUY4X7F1SMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/account/profile.vue")
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    meta: categoryu2S09khqW5Meta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/category.vue"),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93VuFTFINNzmMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue")
  }
]
  },
  {
    name: checkout5MJfqi0C77Meta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkout5MJfqi0C77Meta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/checkout.vue"),
    children: [
  {
    name: cartwzTjo1WrOMMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cartwzTjo1WrOMMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue")
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45paymentDPgxC1R0kQMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue")
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summaryZr8lTMhmegMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue")
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPassword6DXfAI9zVWMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue")
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordiyvJOBoubVMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue")
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/event/index.vue")
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/newsletter/index.vue")
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productrKIhDwSUlAMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/product.vue")
  },
  {
    name: registergy026HR5dsMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registergy026HR5dsMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-customer/pages/[locale]/register.vue")
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolvekwyBSmcbg5Meta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/resolve.vue")
  },
  {
    name: searchuOiKTJMX1tMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchuOiKTJMX1tMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/search.vue"),
    children: [
  {
    name: cms2DnjOQxIB2Meta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cms2DnjOQxIB2Meta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/search/cms.vue")
  },
  {
    name: store526tipTctMMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: store526tipTctMMeta || {},
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-ecom/pages/[locale]/search/store.vue")
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue")
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue")
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue")
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue")
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts")
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts")
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts")
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts")
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/index.vue")
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js")
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/58/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue")
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/58/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue")
  }
]