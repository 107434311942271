export default defineNuxtPlugin(() => {
  const { currentLocale } = useT3i18n()
  const { setHeaders: setMagentoApiHeaders } = useMagentoRestApi()

  watch(currentLocale, (locale) => {
    const { storefronts } = useRuntimeConfig().public
    const currentStore = storefronts[locale]
    setMagentoApiHeaders({ Store: currentStore })
  }, { immediate: true })
})
