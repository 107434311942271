import { storeToRefs } from 'pinia'
import { getQuery } from 'ufo'
import type { CategoryList } from '@ui/components/UiSideNestedMenu/UiSideNestedMenu.types'
import { SearchRouteName, useSearchStore } from '@ecom/stores/search'
import type { CatalogProductsQueryVariables } from '#gql'

function useSearch() {
  const searchStore = useSearchStore()
  const { currentRoute } = useRouter()
  const { callHook } = useNuxtApp()

  const {
    pendingState,
    selectedTab,
    initialized,
    catalogProducts,
    routeQuery,
    searchResults,
    typo3Pagination,
    typo3Results,
    cmsTotalResults,
    storeTotalResults,
    totalResultsCount,
    searchTerm,
    isCategoryTreePending,
    searchBarOptionsList,
    selectedSearchBarOption,
    wasSearchPageAccessedOnSSR,
    isOutlet,
  } = storeToRefs(searchStore)

  const {
    setActiveSearchTab,
    _getMagento2SearchResults,
    getMagento2CategoryTree,
    getTypo3SearchResults,
    getTypoPaginationUrl,
    getProducts,
    getCategoryList,
    isCatalogProductsPending,
    getParamsFromURL,
    resetCatalog,
    getDefaultSearchBarOption,
    getMagento2SearchAggregations,
  } = searchStore

  // When declared directly in the store, and destuctured using 'storeToRefs', values in
  // 'searchBarSavedOptions' localStorage goes back to default values
  const searchBarSavedOptions = useRegionalStorage(
    'search-bar-options',
    {
      selectedOption: getDefaultSearchBarOption(),
      saveSelectedSearchBarSearchOption: false,
    },
    undefined,
    {
      mergeDefaults: true,
    },
  )

  const getMagento2SearchResults = async (parameters?: CatalogProductsQueryVariables) => {
    const data = await _getMagento2SearchResults(selectedSearchBarOption.value.value, parameters)
    await callHook('ecom:page:loading:end', {
      route: currentRoute.value,
    })
    return data
  }

  const getSearchData = async () => {
    const urlParams = getParamsFromURL()
    const [magento = null, typo3 = null] = await Promise.all([
      getMagento2SearchResults({ ...urlParams }),
      getTypo3SearchResults({
        search: urlParams?.search || '',
        page: urlParams?.page,
      }),
    ])

    const data = {
      magento,
      typo3,
    }

    searchResults.value.magento.data = data.magento
    searchResults.value.typo3.data = data.typo3

    return {
      magento,
      typo3,
    }
  }

  /**
   * Sets active tab and fetches data from othar tab than selected
   * Example: if CMS tab is selected, method fetches ecommerce data (we need results count right away)
   * Data for exact tab is fetched in store/cms pages
   */
  const initSearch = async () => {
    setActiveSearchTab()
    await getMagento2SearchAggregations()

    switch (selectedTab.value) {
      case SearchRouteName.CMS: {
        return await getMagento2SearchResults()
      }
      default: {
        await getTypo3SearchResults()
        await getMagento2CategoryTree()
      }
    }
  }

  function getOutermostMagentoCategoryParentNode(node: CategoryList | CategoryList[], urlPath: string): string[] | void {
    const queryParams = getQuery(urlPath)
    if (!Array.isArray(node) && node?.uid === queryParams?.category_uid) {
      return [node.uid]
    }

    const children = Array.isArray(node) ? node : node?.children

    if (children) {
      for (const child of children) {
        const result = getOutermostMagentoCategoryParentNode(child, urlPath)
        if (result) {
          if (!Array.isArray(node)) {
            result.unshift(node.uid)
          }
          return result
        }
      }
    }
  }

  const categoryTree = computed(() => searchResults.value?.magento?.tree)

  onMounted(() => {
    if (searchBarSavedOptions.value.saveSelectedSearchBarSearchOption && !wasSearchPageAccessedOnSSR.value) {
      selectedSearchBarOption.value = searchBarSavedOptions.value.selectedOption
    }
  })

  watch(() => searchBarSavedOptions.value.saveSelectedSearchBarSearchOption, () => {
    searchBarSavedOptions.value.selectedOption = selectedSearchBarOption.value
  })

  return {
    pendingState,
    selectedTab,
    initialized,
    setActiveSearchTab,
    initSearch,
    searchResults,
    getProducts,
    getCategoryList,
    getSearchData,
    getMagento2SearchResults,
    getMagento2CategoryTree,
    getTypo3SearchResults,
    catalogProducts,
    getTypoPaginationUrl,
    isCatalogProductsPending,
    typo3Pagination,
    typo3Results,
    routeQuery,
    getParamsFromURL,
    resetCatalog,
    cmsTotalResults,
    storeTotalResults,
    totalResultsCount,
    searchTerm,
    isCategoryTreePending,
    getOutermostMagentoCategoryParentNode,
    categoryTree,
    getDefaultSearchBarOption,
    searchBarOptionsList,
    selectedSearchBarOption,
    searchBarSavedOptions,
    wasSearchPageAccessedOnSSR,
    isOutlet,
  }
}

export { useSearch }
