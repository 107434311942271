import { defu } from 'defu'
import useStorefrontData from '@base/composables/useStorefrontData'
import { useStoreConfig } from '@ecom/composables/useStoreConfig/useStoreConfig'

export type FetchOptions = Parameters<typeof $fetch>[1]
type RequestHeaders = Record<string, string>

const DEFAULT_FETCH_OPTIONS: FetchOptions = {
  method: 'GET',
} as const

export function useMagentoRestApi() {
  const { customerToken } = useCustomer()
  const headers = useState<RequestHeaders>('magento-api-headers', () => ({}))
  const config = useRuntimeConfig()

  const baseUrl = config.public.magento2.rest

  const getRequestHeaders = () => {
    const requestHeaders: RequestHeaders = { ...headers.value }
    const { currencyData } = useStoreConfig()

    if (customerToken.value) {
      requestHeaders.Authorization = `Bearer ${customerToken.value}`
    }

    if (currencyData.value?.currencyCode) {
      requestHeaders['Content-Currency'] = currencyData.value?.currencyCode
    }

    return requestHeaders
  }

  const queryMagento = <T>(
    endpoint: string,
    options: FetchOptions = {},
  ) => {
    const { isDefinedStorefront } = useStorefrontData()
    if (!isDefinedStorefront.value) {
      throw new Error('Cannot query Magento due to invalid locale')
    }

    const { callHook } = useNuxtApp()
    const baseOptions: FetchOptions = {
      ...DEFAULT_FETCH_OPTIONS,
      headers: getRequestHeaders(),
      onRequest(context) {
        callHook('ecom:rest:onRequest', context)
      },
      onRequestError(context) {
        callHook('ecom:rest:onRequestError', context)
      },
      onResponse(context) {
        callHook('ecom:rest:onResponse', context)
      },
      onResponseError(context) {
        callHook('ecom:rest:onResponseError', context)
      },
    }

    return $fetch<T>(
      `${baseUrl}${endpoint}`,
      defu(options, baseOptions),
    )
  }

  const setHeaders = (newHeaders: RequestHeaders) => {
    headers.value = defu(newHeaders, headers.value)
  }

  return {
    queryMagento,
    setHeaders,
  }
}
