import type { GqlError } from 'nuxt-graphql-client'

interface ErrorData {
  message: string
  id: string
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      parseGqlError: (error: GqlError) => {
        let errors: ErrorData | null = null
        if ('gqlErrors' in error) {
          const data = error?.gqlErrors?.[0]
          errors = {
            message: data?.message,
            id: data?.extensions?.category as string,
          }
        }

        return errors
      },
    },
  }
})
