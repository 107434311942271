import type { T3Navigation } from '@t3headless/nuxt-typo3'
import { useCatalogQueries } from '@ecom/composables/useCatalogQueries'
import useConditionalRender from '@base/composables/useConditionalRender/useConditionalRender'
import type { AppNavLink } from './AppNav.types'
import type { CategoryListQuery, CategoryTree } from '#gql'

type linkData =
  | {
    path: string
    suffix?: string
    prefix?: string
  }
  | string

/**
 * Map magento categories objects to consistent one (TYPO3)
 * @param navigation
 */

export const navigation = () => useState('appNav', (): AppNavLink[] => [])
export const appNavTeleportArea = () => useState<HTMLDivElement | undefined>('appNavTeleportArea', () => undefined)

export function useAppNav() {
  const state = navigation()
  const currentLocale = useT3i18nState()
  const teleportArea = useState<HTMLDivElement | undefined>('appNavTeleportArea', () => undefined)

  const useT3LocalePath = (path?: string) => {
    return `/${currentLocale.value}${path ? `/${path}` : ''}`
  }

  function mapM2NavigationToT3(navigation: CategoryTree[]): AppNavLink[] {
    const list: AppNavLink[] = []
    navigation.forEach(
      ({
        name,
        children_count,
        uid,
        children,
        canonical_url,
        include_in_menu,
        level,
      }) => {
        if (
          !name
          || typeof canonical_url !== 'string'
          || !uid
          || !include_in_menu
        ) {
          return
        }

        const link: AppNavLink = {
          title: name,
          link: import.meta.server
            ? computed(() => useCategoryLinkWithContext(canonical_url))
            : useCategoryLinkWithContext(canonical_url),
          children_count: Number(children_count),
          uid,
          level,
        }
        if (children) {
          link.children = mapM2NavigationToT3(children)
        }

        list.push({ ...link })
      },
    )
    return list
  }

  const appLink = (linkData: linkData): string => {
    if (typeof linkData === 'string') {
      return useT3LocalePath(linkData)
    }
    return useT3LocalePath(
      `${linkData.prefix}/${linkData.path}${linkData.suffix || ''}`,
    )
  }

  const productLink = (path: string): string => {
    return appLink({
      path,
      prefix: 'p',
    })
  }

  const categoryLink = (path: string): string => {
    return appLink({
      path,
      prefix: 'c',
    })
  }

  // Copy of 'useCategoryLink' method. Prevents lost nuxt instance context
  const useCategoryLinkWithContext = (path: string): string => {
    return categoryLink(path)
  }

  /**
   * Get initialData and merge with first level of Magento categories
   * @param initialNavigation
   */
  const initNavigation = async (
    initialNavigation: T3Navigation[],
    forceRefresh = false,
  ) => {
    if (import.meta.client && !forceRefresh) {
      return
    }
    if (forceRefresh) {
      state.value = []
    }
    if (initialNavigation?.length) {
      state.value = initialNavigation
    }

    const { isEcommerceEnabled } = useConditionalRender()
    if (isEcommerceEnabled.value) {
      const { getCategoryList } = useCatalogQueries()
      try {
        const categories: CategoryListQuery = await getCategoryList({
          parentUid: 'MQ==', // 1 in base64
          nested: true,
        })
        if (categories?.categories?.items?.[0]?.children) {
          const m2navigation = mapM2NavigationToT3(
            categories.categories.items as CategoryTree[],
          )?.[0]?.children

          if (m2navigation) {
            state.value.unshift(...m2navigation)
          }
        }
      }
      catch (error) {
        console.error(error)
      }
    }
  }

  // force refresh navigation
  const refreshNavigation = async (initialDataNav: T3Navigation[]) => {
    await initNavigation(initialDataNav, true)
  }

  return {
    initNavigation,
    refreshNavigation,
    productLink,
    categoryLink,
    appLink,
    navigation: state,
    teleportArea,
  }
}

export function useCategoryLink(path: string): string {
  const { categoryLink } = useAppNav()
  return categoryLink(path)
}
