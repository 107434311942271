import { storeToRefs } from 'pinia'
import { useCatalogStore } from '@ecom/stores/catalog'
import { FILTERS_SUFFIX, useQuery } from '../useQuery/useQuery'
import type {
  AttributeFilter,
  ClearableFilterInterface,
  SelectedFiltersInterface,
} from './useFilters.types'
import { FrontendInputEnum } from './useFilters.types'
import type { Aggregation, AggregationOption, ProductAttributeFilterInput } from '#gql'

export const DefaultNumberOfFiltersToShow = 4
export const rangeFilters = ['price']
export const excludedFilters = new Set([
  'category_uid',
  `category_uid${FILTERS_SUFFIX}`,
])

export function useFilters() {
  const store = useCatalogStore()
  const { getParamsFromURL } = useQuery()

  const { selectedFilters } = storeToRefs(store)
  const { setSelectedFilters, setClearableFilters } = store

  /* get filters from url (omits excluded filters) and returns key-value pairs */
  const getSelectedFiltersFromUrl = () => {
    const selectedFilterValues = {}
    const { filters } = getParamsFromURL()

    Object.keys(filters).forEach((filter) => {
      if (!excludedFilters.has(filter)) {
        selectedFilterValues[filter] = filters[filter]
      }
    })

    return selectedFilterValues
  }

  setSelectedFilters(getSelectedFiltersFromUrl())

  // In order to distinguish filters from other params, we need to add suffix to the attribute code
  const addSuffixToFiltersAttributeCode = (filters: Aggregation[]) => {
    return (filters ?? []).map(aggregation => ({
      ...aggregation,
      attribute_code: `${aggregation.attribute_code}${FILTERS_SUFFIX}`,
    }))
  }

  const isFilterSelected = (
    attrCode: string,
    value: string | AggregationOption[],
  ) => {
    /* while using select component, we need to iterate over its options, and then check if we can find
    any given option in selectedFilterValues */
    if (Array.isArray(value)) {
      const selectedValues: AggregationOption[] = []
      value.forEach((option) => {
        const foundOption = (selectedFilters.value?.[attrCode] ?? []).find(
          selectedValue => option.value === selectedValue,
        )

        if (foundOption) { selectedValues.push(option) }
      })
      return selectedValues
    }
    return (
      (selectedFilters.value?.[attrCode] ?? []).find(
        selectedValue => selectedValue === value,
      ) ?? []
    )
  }
  const removeFilter = (attrCode: string, valueToRemove: string) => {
    if (!selectedFilters.value?.[attrCode]) {
      return
    }
    selectedFilters.value[attrCode] = selectedFilters.value[attrCode].filter(
      value => value !== valueToRemove,
    )
  }

  const removeAllFilters = () => {
    setSelectedFilters({})
    setClearableFilters([])
  }

  const selectFilter = (
    filter: Aggregation,
    option: AggregationOption[] | AggregationOption,
  ) => {
    if (!selectedFilters.value?.[filter.attribute_code]) {
      Object.assign(selectedFilters.value, { [filter.attribute_code]: [] })
    }

    if (
      filter.frontend_input === FrontendInputEnum.MULTISELECT
      && Array.isArray(option)
    ) {
      const optionsValues = option.map(item => item.value)
      selectedFilters.value[filter.attribute_code] = [
        ...new Set([
          ...selectedFilters.value[filter.attribute_code],
          ...optionsValues,
        ]),
      ]
      return
    }
    selectedFilters.value[filter.attribute_code] = [option.value]
  }

  /**
   * 1. Check if there are any properties in attribute filter object that do not have
   * filter suffix at the end. Is so, remove it.
   *
   * 2.Remove suffix from all remaining properties in order to provide valid magento
   * payload (without suffix)
   */
  const clearProductAttributeFilterInput = (
    attributeFilterObject: AttributeFilter,
  ) => {
    Object.keys(attributeFilterObject)
      .filter(key => !key.endsWith(FILTERS_SUFFIX))
      .forEach(key => delete attributeFilterObject[key])

    for (const key in attributeFilterObject) {
      if (key.endsWith(FILTERS_SUFFIX)) {
        const newKey = key.slice(0, key.length - FILTERS_SUFFIX.length)
        const descriptor = Object.getOwnPropertyDescriptor(
          attributeFilterObject,
          key,
        )
        delete attributeFilterObject[key]
        Object.defineProperty(attributeFilterObject, newKey, descriptor)
      }
    }
  }

  const getSearchBarFilters = (params: {
    name?: {
      match: string
    }
    sku_matchable?: {
      match: string
    }
    barcode?: {
      match: string
    }
    aggregated_concurrency_index?: {
      match: string
    }
  }) => {
    const filters = {}

    if (!params) {
      return filters
    }

    if (params?.name?.match) {
      Object.assign(filters, {
        name: params.name,
      })
    }

    if (params?.sku_matchable?.match) {
      Object.assign(filters, {
        sku_matchable: params.sku_matchable,
      })
    }

    if (params?.barcode?.match) {
      Object.assign(filters, {
        barcode: params.barcode,
      })
    }

    if (params?.aggregated_concurrency_index?.match) {
      Object.assign(filters, {
        aggregated_concurrency_index: params.aggregated_concurrency_index,
      })
    }

    return filters
  }

  /**
   * Function that takes parameters that we want to filter with
   * and returns suitable Magento 2 filter input
   *
   * If we set 'clearFilterInput' to true, all filters without [filter] suffix will
   * be removed
   */
  const createProductAttributeFilterInput = (
    params: any,
    clearFilterInput = true,
  ): ProductAttributeFilterInput => {
    const attributeFilter: AttributeFilter = {}
    const inputFilters = params?.filters ?? {}

    const productsFilters = {}
    if (params.category_uid || inputFilters.category_uid) {
      const categoryUid = Array.isArray(params.category_uid) && params.category_uid.length ? params.category_uid[0] : params.category_uid

      const categoryUids = []

      if (categoryUid) {
        categoryUids.push(categoryUid)
      }

      if (inputFilters?.category_uid) {
        categoryUids.push(...inputFilters.category_uid)
      }

      Object.assign(productsFilters, {
        category_uid: {
          in: categoryUids,
        },
      })
    }

    Object.assign(productsFilters, getSearchBarFilters(params))

    /**
     * We want to display only simple products (SimpleProduct) in
     * category productslist
     */
    const productTypeFilter = {
      type_id: {
        in: ['simple'],
      },
    }

    Object.keys(inputFilters).forEach((key: string) => {
      if (rangeFilters.includes(key)) {
        const range = { from: 0, to: 0 }
        const flatValue = inputFilters[key]
          .flatMap(inputFilter => inputFilter.split('_'))
          .map((str: string) => Number.parseFloat(str))
          .sort((a, b) => a - b)

        ;[range.from] = flatValue
        range.to = flatValue[flatValue.length - 1]

        attributeFilter[key] = range
      }
      else if (typeof inputFilters[key] === 'string') {
        attributeFilter[key] = { eq: inputFilters[key] }
      }
      else if (inputFilters[key].length === 1) {
        attributeFilter[key] = { eq: inputFilters[key][0] }
      }
      else {
        attributeFilter[key] = { in: inputFilters[key] }
      }
    })

    if (clearFilterInput) {
      clearProductAttributeFilterInput(attributeFilter)
    }

    return { ...attributeFilter, ...productsFilters, ...productTypeFilter }
  }
  const getClearableFilters = (
    filters: Aggregation[],
    selectedFilters: SelectedFiltersInterface,
  ): ClearableFilterInterface[] => {
    const clearableFilters: ClearableFilterInterface[] = []

    const adjustedFilters = addSuffixToFiltersAttributeCode(filters)

    adjustedFilters.forEach((filter) => {
      filter.options.forEach((option) => {
        if (
          (selectedFilters?.[filter.attribute_code] ?? []).includes(
            option?.value ?? '',
          )
        ) {
          const {
            attribute_code: attributeCode,
            label,
            frontend_input: frontendInput,
          } = filter
          const { label: optionLabel = '', value: optionValue = '' } = option

          clearableFilters.push({
            attributeCode,
            label,
            frontendInput,
            option: {
              label: optionLabel,
              value: optionValue,
            },
          })
        }
      })
    })

    return clearableFilters
  }

  return {
    DefaultNumberOfFiltersToShow,
    getSelectedFiltersFromUrl,
    excludedFilters,
    selectedFilters,
    isFilterSelected,
    selectFilter,
    removeFilter,
    removeAllFilters,
    getClearableFilters,
    createProductAttributeFilterInput,
    addSuffixToFiltersAttributeCode,
    getSearchBarFilters,
  }
}
