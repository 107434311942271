/**
 * This store is client only and is responsble for handling individual product defaultLocale
 * like prices, quantity, product aliast, etc.
 */

import { defineStore } from 'pinia'
import type { GqlError } from 'nuxt-graphql-client'
import type { IndividualDataMinimalProduct, IndividualProductDataState } from './individual.types'
import type { GetCustomerIndividualProductsDataQuery, GetCustomerIndividualProductsDataQueryVariables, ProductQtyOutput } from '#gql'

export const useIndividualProductDataStore = defineStore('individualProductData', {
  state: (): IndividualProductDataState => {
    return {
      customerAliases: [],
      customerPrices: [],
      productQty: [],
      products: [],
      _states: {
        pending: false,
        error: null,
        success: null,
      },
    }
  },
  actions: {
    async fetchIndividualProductData(skus: string[], options: Omit<GetCustomerIndividualProductsDataQueryVariables, 'skus'>) {
      if (!skus?.length) {
        return
      }

      const { isLoggedIn } = useCustomer()

      if (!isLoggedIn.value) {
        return
      }

      const { $parseGqlError } = useNuxtApp()
      const route = useRoute()

      this._states.pending = true
      this._states.error = null

      const { data, pending, error } = await useAsyncData(
        `individualData-${route.fullPath}`,
        () => GqlGetCustomerIndividualProductsData({
          skus,
          pageSize: skus.length,
          alias: options.alias,
          price: options.price,
          qty: options.qty,
        }),
      )

      this._states.pending = pending.value

      if (error.value) {
        this._states.error = $parseGqlError(error.value as unknown as GqlError)?.message || 'Error occurred'
      }

      if (data.value) {
        this.setIndividualProductData(data.value)
      }

      return data.value
    },
    setIndividualProductData(data: GetCustomerIndividualProductsDataQuery) {
      this.customerAliases = _mergeIndividualData(this.customerAliases, data.customerAliases)
      this.customerPrices = _mergeIndividualData(this.customerPrices, data.customerPrices)
      this.productQty = _mergeIndividualData(this.productQty, data.productQty)
      this.products = _mergeIndividualData(this.products, data.products?.items)
    },
  },
  getters: {
    getProduct: (state) => {
      return (product: IndividualDataMinimalProduct) => state.products?.find(item => item?.sku === product.sku) ?? null
    },
    getProductQty: (state) => {
      return (product: IndividualDataMinimalProduct): ProductQtyOutput | null => state.productQty?.find(item => item?.sku === product.sku) ?? null
    },
    getProductAlias: (state) => {
      return (product: IndividualDataMinimalProduct) => state.customerAliases?.find(item => item?.sku === product.sku)
    },
    getProductIndividualCustomerPrice: (state) => {
      return (product: IndividualDataMinimalProduct) => state.customerPrices?.find(item => item?.sku === product.sku)
    },
    getProductIndividualPrice: (state) => {
      return (product: IndividualDataMinimalProduct) => state.products?.find(item => item?.sku === product.sku)
    },
    getProductPriceTiers: (state) => {
      return (product: IndividualDataMinimalProduct) => state.products?.find(item => item?.sku === product.sku)?.price_tiers ?? []
    },
    pending: state => state._states.pending,
  },
})

/**
 * Merge current individual data with new data
 * @param current - individual data response object
 * @param newData - individual data response object
 */
function _mergeIndividualData<T>(current: T | undefined | null, newData: T | undefined | null): T | [] {
  if (!current) { return [] }
  if (!newData) { return current }

  if (Array.isArray(current) && Array.isArray(newData)) {
    const result = [...current]
    if (newData) {
      newData.forEach((item) => {
        const index = result.findIndex(i => i?.sku === item?.sku)
        if (index !== -1) {
          result[index] = item
        }
        else {
          result.push(item)
        }
      })
    }
    return result as T
  }
  return []
}
