import { useCheckbox } from '../UiForm/UiCheckbox/composables/useCheckbox'

export function useBaseTable() {
  const { isIndeterminateState } = useCheckbox()
  const updateSelectedRow = (
    selectedRows: string[],
    selectableRowsCount: number,
    id: string,
  ) => {
    const rowsIds = [...selectedRows]

    if (rowsIds.includes(id)) {
      rowsIds.splice(rowsIds.indexOf(id), 1)
      return {
        selectAllCheckboxIndeterminate: isIndeterminateState(
          rowsIds,
          selectableRowsCount,
        ),
        selectAllCheckboxSelected: Boolean(rowsIds.length),
        selectedRows: rowsIds,
      }
    }
    rowsIds.push(id)
    return {
      selectAllCheckboxIndeterminate: isIndeterminateState(
        rowsIds,
        selectableRowsCount,
      ),
      selectAllCheckboxSelected: Boolean(rowsIds.length),
      selectedRows: rowsIds,
    }
  }

  const selectAllRows = <T extends { name: string }>(
    tableRows: T[],
    selectedRows: string[],
  ) => {
    if (!tableRows) {
      return null
    }

    if (selectedRows.length < tableRows.length) {
      const rows = [] as string[]
      tableRows.forEach((item) => {
        if (item?.name) {
          rows.push(item.name)
        }
      })
      return {
        selectAllCheckboxIndeterminate: false,
        selectAllCheckboxSelected: true,
        selectedRows: rows,
      }
    }
    return {
      selectAllCheckboxIndeterminate: false,
      selectAllCheckboxSelected: false,
      selectedRows: [],
    }
  }

  return {
    updateSelectedRow,
    selectAllRows,
    isIndeterminateState,
  }
}
