import { setResponseHeader } from 'h3'
import { useStoreConfig } from '@ecom/composables/useStoreConfig/useStoreConfig'
import { useRegionalCookie } from '@base/composables/useRegionalStorage'
import type { GqlClients } from '#gql'

interface RequestHeader {
  name: string
  value: string
}
type API = 'typo3' | 'magento2'
export interface SetHeaderOptions {
  clearAuthorizationHeader: boolean
}

export default defineNuxtPlugin((nuxtApp) => {
  function setHeader(
    header: RequestHeader,
    api: API[] = ['typo3', 'magento2'],
  ) {
    if (api.includes('magento2')) {
      const gqlClients
        = nuxtApp.$config?.public?.['graphql-client']?.clients ?? []
      const clients = Object.keys(gqlClients) as GqlClients[]

      clients.forEach((client) => {
        const clientConfig = gqlClients[client]
        if (clientConfig.preferGETQueries && header.name === 'Authorization') {
          // Authorization header is should not be set for GET queries
          // for Varnish support
          return
        }
        useGqlHeaders(
          {
            [header.name]: header.value,
          },
          client,
        )
      })
    }

    if (api.includes('typo3') && nuxtApp?.$typo3) {
      nuxtApp.$typo3.api.setHeaders({
        [header.name]: header.value,
      })
    }
  }

  function setHeaders(options?: SetHeaderOptions) {
    /**
     * Set language header
     */
    const { currentLocale } = useT3i18n()
    const { storefronts } = useRuntimeConfig().public
    const currentStore = storefronts[currentLocale.value]
    if (currentLocale.value && currentStore) {
      setHeader(
        {
          name: 'Store',
          value: currentStore,
        },
        ['magento2'],
      )
    }

    /**
     * Set currency header
     */
    const { currencyData } = useStoreConfig()
    if (currencyData.value?.currencyCode) {
      setHeader(
        {
          name: 'Content-Currency',
          value: currencyData.value?.currencyCode,
        },
        ['magento2', 'typo3'],
      )
    }

    /**
     * Set customer token header
     */
    const tokenCookie = useRegionalCookie('token')
    const clearAuthorizationHeader = options?.clearAuthorizationHeader ?? false

    if (tokenCookie.value && !clearAuthorizationHeader) {
      setHeader(
        {
          name: 'Authorization',
          value: `Bearer ${tokenCookie.value}`,
        },
        ['magento2'],
      )
    }

    if (clearAuthorizationHeader) {
      setHeader(
        {
          name: 'Authorization',
          value: '',
        },
        ['magento2'],
      )
    }
  }

  setHeaders()

  /**
   * Set language header on locale change
   */
  if (import.meta.client) {
    nuxtApp.hook('t3:i18n', (newLocale: string) => {
      const { storefronts } = useRuntimeConfig().public
      const currentStore = storefronts[newLocale]
      setHeader(
        {
          name: 'Store',
          value: currentStore,
        },
        ['magento2'],
      )
    })
  }

  /**
   * Set Origin header for CORS Requests
   * we can't use useRequestURL().origin because we use nginx proxy
   */
  if (import.meta.server) {
    const { siteUrl } = useRuntimeConfig().public
    const headers = useRequestHeaders(['x-forwarded-proto', 'host'])
    let origin = siteUrl
    if (headers['x-forwarded-proto'] && headers.host) {
      origin = `${headers['x-forwarded-proto']}://${headers.host}`
    }

    if (origin) {
      setResponseHeader(useRequestEvent(), 'Origin', origin)
    }
  }

  return {
    provide: {
      setHeaders: (options?: SetHeaderOptions) => setHeaders(options),
    },
  }
})
