import { useRoute, useRouter } from 'vue-router'
import type { LocationQuery } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useCatalogStore } from '@ecom/stores/catalog'
import { usePageSize } from '../usePageSize/usePageSize'
import type { FilterParams, QueryParams, UseQuery } from './useQuery.types'

/**
 * we need to specify array of items not being filters, in order to avoid them when
 *  looping through query params
 */
const nonFilterParams = new Set(['page', 'sort', 'pageSize', 'term'])
export const FILTERS_SUFFIX = `[filter]`

function transformFiltersObjectValues(query: QueryParams) {
  return (prev: FilterParams, curr: string): FilterParams => {
    const isArray = Array.isArray(query[curr])
    /* if current query value is array, filter out falsy values such as null or undefined */
    const currentQueryValue = isArray
      ? query[curr].filter(item => item)
      : query[curr]

    let finalParamValue = [] as string[]

    if (typeof currentQueryValue === 'string') { finalParamValue = [currentQueryValue as string] }
    if (Array.isArray(currentQueryValue) && currentQueryValue.length) {
      finalParamValue = currentQueryValue as string[]
    }

    return {
      ...prev,
      // if param's array is empty, don't add that param
      ...(finalParamValue.length && { [curr]: finalParamValue }),
    }
  }
}

export function useQuery(): UseQuery {
  const store = useCatalogStore()
  const { params } = storeToRefs(store)
  const { setParams, setSelectedFilters } = store
  const route = useRoute()
  const router = useRouter()
  const { getPageSizeValue, changePageSizeCookie } = usePageSize()

  const sanitizeFilters = <T>(filters: T) => {
    if (
      typeof filters !== 'object'
      || filters == null
      || Array.isArray(filters)
    ) {
      return {}
    }

    const result: Record<string, any> = {}

    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        result[key] = (filters[key] as unknown[]).map((value) => {
          if (typeof value === 'string') {
            // encodeURIComponent causes issues with base64 formatted value
            if (key === 'category_uid') {
              return value
            }
            return encodeURIComponent(value)
          }
          return value
        })
      }
      else if (typeof filters[key] === 'object' && filters[key] !== null) {
        result[key] = sanitizeFilters(filters[key])
      }
      else if (typeof filters[key] === 'string') {
        result[key] = encodeURIComponent(filters[key] as string)
      }
      else {
        result[key] = filters[key]
      }
    }

    return result
  }

  /**
   * Gets specified query params (only filters, only non filters or all params)
   * @param includeOnlyFilters
   * @param includeOnlyNonFiltersParams
   */
  const getDataFromUrl = (
    includeOnlyFilters = false,
    includeOnlyNonFiltersParams = false,
  ): FilterParams => {
    let onlyFilters = includeOnlyFilters
    let onlyNonFilters = includeOnlyNonFiltersParams

    if (onlyFilters) { onlyNonFilters = false }
    if (onlyNonFilters) { onlyFilters = false }

    const currentQuery = route.query
    return sanitizeFilters(
      Object.keys(currentQuery)
        .filter((param) => {
          if (onlyFilters) {
            return !nonFilterParams.has(param) && param.endsWith(FILTERS_SUFFIX)
          }

          if (onlyNonFilters) {
            return nonFilterParams.has(param)
          }

          return param
        })
        .reduce(transformFiltersObjectValues(currentQuery), {}),
    )
  }

  /**
   * Gets formatted object with all needed url query params
   * @returns {QueryParams}
   */

  const getParamsFromURL = (): QueryParams => {
    const routeQuery = route.query
    const currentQuery: LocationQuery | LocationQuery[] = {}

    /* if query from non filter params is an array - transform it to a string  */

    Object.keys(routeQuery).forEach((query) => {
      if (nonFilterParams.has(query)) {
        Object.assign(currentQuery, {
          ...(Array.isArray(routeQuery[query]) && {
            [query]: routeQuery?.[query]?.[0] ?? '',
          }),
          ...(typeof routeQuery[query] === 'string' && {
            [query]: routeQuery?.[query] ?? '',
          }),
        })
      }
    })

    return {
      search: currentQuery.term,
      filters: getDataFromUrl(true),
      pageSize: getPageSizeValue(
        Number.parseInt(encodeURIComponent(currentQuery.pageSize), 10),
      ),
      page: Number.parseInt(encodeURIComponent(currentQuery.page), 10) || 1,
      sort: encodeURIComponent(currentQuery.sort),
    }
  }

  /**
   * Updates query and store with current page
   *
   * @param {number} page
   */
  const changePage = async (page: number) => {
    if (route.query?.page === page.toString() || params.value.page === page) {
      return
    }

    const sanitizedPage = encodeURIComponent(page)

    setParams({ page })

    const currentQuery = {
      ...route.query,
      page: sanitizedPage,
    } as LocationQuery

    if (currentQuery?.page === '1') {
      delete currentQuery.page
    }

    await router.push({
      query: currentQuery,
    })
  }

  /**
   * Updates query and store with page size (number of items to display)
   *
   * @param pageSize
   */
  const changePageSize = async (pageSize: number) => {
    /*  page needs to be set to 1 (after page size change, total pages can change) */
    const sanitizedPageSize = encodeURIComponent(pageSize)
    changePageSizeCookie(pageSize)
    setParams({ pageSize, page: 1 })

    const currentQuery = {
      ...route.query,
      pageSize: sanitizedPageSize,
    } as LocationQuery

    if (currentQuery?.page) {
      delete currentQuery.page
    }
    await router.push({
      query: currentQuery,
    })
  }

  /**
   * Updates query and store with selected sort option
   *
   * @param sortOption
   */
  const changeSortOption = async (sortOption: string) => {
    const sanitizedSortOption = encodeURIComponent(sortOption)
    setParams({ sort: sanitizedSortOption })
    await router.push({
      query: {
        ...route.query,
        sort: sanitizedSortOption,
      },
    })
  }

  /**
   * Updates query with filters values
   *
   * @param filters
   */
  const changeFilters = async (
    filters: FilterParams | null,
    removeAll = false,
  ): Promise<void> => {
    /*  page needs to be set to 1 (after setting some filters, total pages can change) */
    setParams({ page: 1 })
    const sanitizedFilters = sanitizeFilters<FilterParams>(filters)
    setSelectedFilters(sanitizedFilters)

    const currentQuery = {
      ...(!removeAll && getDataFromUrl()),
      ...(removeAll && getDataFromUrl(false, true)),
      ...sanitizedFilters,
    }

    if (currentQuery?.page) {
      delete currentQuery.page
    }

    await router.push({
      query: currentQuery,
    })
  }

  return {
    getDataFromUrl,
    getParamsFromURL,
    changePage,
    changePageSize,
    changeSortOption,
    changeFilters,
  }
}
