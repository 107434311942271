export enum FrontendInputEnum {
  SELECT = 'select',
  MULTISELECT = 'multiselect',
}

/* null value is for price and category_uid filters */
export type FrontendInput = 'select' | 'multiselect' | null

/**
 * Custom filter fields (i.e. price or category_uid) don't have assigned value to frontend_input (null)
 */
export enum CustomFilterFields {
  PRICE = 'price',
  CATEGORY_UID = 'category_uid',
}

/**
 * Parameters received by URL search/query params that aren't filters. They're
 * used for searching, sorting and pagination.
 */
export interface SelectedFiltersInterface {
  [p: string]: string[]
}

export interface ClearableFilterInterface {
  attributeCode: string
  label: string
  frontendInput: FrontendInput
  option: {
    label: string
    value: string
  }
}

export interface NonFilterParams {
  pageSize?: string
  page?: string
  sort?: string
}

/** Parameters received by URL search/query params. */
export interface QueryParams extends NonFilterParams {
  [key: string]: string | (string | null)[]
}

/** Parameters to filter products and other entities. */
export interface FilterParams {
  [key: string]: (null | string)[]
}

/**
 * The normalized parameters received by URL search/query params for filtering,
 * searching, sorting and pagination.
 */
export interface Params {
  filters: FilterParams
  pageSize: number
  page: number
  sort: string
  term?: string
}

export type AttributeFilter = Record<
  string,
  { from: number, to: number } | { eq: unknown } | { in: unknown }
>
