import useConditionalRender from '../composables/useConditionalRender/useConditionalRender'

export default defineNuxtRouteMiddleware((to) => {
  const { isRenderAllowed } = useConditionalRender({
    modules: to.meta?.conditions?.modules ?? [],
    flags: to.meta?.conditions?.flags ?? [],
  })

  if (!isRenderAllowed.value) {
    return abortNavigation(
      createError({
        statusCode: 404,
        fatal: true,
      }),
    )
  }
})
