import { nextTick } from 'vue'

const ScrollIntoViewDefaultSettings = {
  behavior: 'smooth',
  block: 'center',
} as ScrollIntoViewOptions

/**
 * Method iterates over vee-validate error messages and scroll to one if found
 * @param {Record<string, string>} errors
 * @param {HTMLElement} el
 * @param {ScrollIntoViewOptions} scrollIntoViewOptions
 */
export async function scrollToFirstValidationError(errors: Partial<Record<string, string>>, el: HTMLElement, scrollIntoViewOptions: ScrollIntoViewOptions = ScrollIntoViewDefaultSettings) {
  if (!errors) {
    return
  }
  await nextTick()

  const firstErrorFieldName = Object.keys(errors)?.[0] ?? ''
  const inputElement = el.querySelector(`[name='${firstErrorFieldName}']`)

  if (inputElement) {
    inputElement?.scrollIntoView(scrollIntoViewOptions)
  }
}
