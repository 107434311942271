import type {
  CategoryFiltersQueryVariables,
  CategoryListQueryVariables,
  CategoryQueryVariables,
} from '#gql'

export function useCatalogQueries() {
  const getCategory = async (variables: CategoryQueryVariables) =>
    await GqlCategory(variables)

  const getCategoryList = async (variables: CategoryListQueryVariables) =>
    await GqlCategoryList(variables)

  const categoryFilters = async (variables: CategoryFiltersQueryVariables) =>
    await GqlCategoryFilters(variables)

  return {
    getCategory,
    getCategoryList,
    categoryFilters,
  }
}
