<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <!-- if button component will come as an HTML element from the server, 'group'
    class needs to be applied separately, next to 'btn' class -->
  <component
    v-bind="{
      ...(!props.to && {
        type: $attrs?.type ?? undefined,
      }),
    }"
    :is="buttonTag"
    ref="uiHTMLButtonElement"
    :to="props.to ? props.to : undefined"
    class="btn group"
    :title="props.title"
    :aria-label="props.title"
    :class="[
      buttonClasses,
      {
        'btn--disabled': props.disabled,
        'btn--no-animation': !props.withAnimation,
        'btn--uppercase': props.uppercase,
      },
      iconLeft || iconLeft ? 'justify-between' : 'justify-center',
    ]"
    :aria-disabled="props.disabled"
    :disabled="props.disabled"
    @click="handleClick"
  >
    <UiButtonLoading
      v-if="props.loading"
      :class="{ invert: props.disabled }"
    />
    <slot
      name="iconLeft"
      v-bind="{ iconLeft }"
    >
      <UiIcon
        class="btn__icon"
        :name="leftSideIcon?.name"
        :width="leftSideIcon?.width"
        :height="leftSideIcon?.height"
      />
    </slot>

    <template v-if="$slots.default">
      <span
        v-if="props.variant !== 'icon'"
        class="btn__content"
        :class="[
          {
            'btn__content--animated':
              props.withAnimation && props.variant === 'circle',
          },
        ]"
      >
        <slot />
      </span>
      <template v-else>
        <slot />
      </template>
    </template>

    <slot
      name="iconRight"
      v-bind="{ iconRight }"
    >
      <UiIcon
        class="btn__icon"
        :class="[
          { 'group-hover:translate-x-0.5': variant === 'link' },
        ]"
        :name="rightSideIcon?.name"
        :width="rightSideIcon?.width"
        :height="rightSideIcon?.height"
      />
    </slot>
  </component>
</template>

<script lang="ts" setup>
import { hasProtocol } from 'ufo'
import { computed, ref, resolveComponent } from 'vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import type { UiIconProps } from '../UiIcon/UiIcon.types'
import UiButtonLoading from './UiButtonLoading.vue'
import type { ButtonVariants } from './UiButton.types'

interface UiButton {
  tag?: string
  to?: string | null
  title?: string
  disabled?: boolean | null
  variant?: ButtonVariants
  uppercase?: boolean
  slim?: boolean
  verySlim?: boolean
  long?: boolean
  fullWidth?: boolean
  iconLeft?: UiIconProps | string | null
  iconRight?: UiIconProps | string | null
  iconDimensions?: {
    width: number
    height: number
  } | null
  withAnimation?: boolean
  loading?: boolean
  noLinkIcon?: boolean
}

const props = withDefaults(defineProps<UiButton>(), {
  tag: 'button',
  to: null,
  title: '',
  disabled: false,
  variant: 'primary',
  uppercase: true,
  slim: false,
  verySlim: false,
  long: false,
  fullWidth: false,
  iconLeft: null,
  iconRight: null,
  iconDimensions: () => ({
    width: 15,
    height: 15,
  }),
  withAnimation: false,
  loading: false,
  noLinkIcon: false,
})

const emit = defineEmits<{
  (e: 'click', event: MouseEvent): void
}>()

const uiHTMLButtonElement = ref<HTMLButtonElement>()

const isExternalLink = computed(() => {
  if (!props.to) {
    return
  }

  return hasProtocol(props.to, { acceptRelative: true })
})

const buttonTag = computed(() => {
  if (props.to) {
    return resolveComponent('nuxt-link')
  }
  return props.tag ? props.tag : 'button'
})

const leftSideIcon = computed(() => {
  return typeof props.iconLeft === 'string'
    ? { ...props.iconDimensions, name: props.iconLeft }
    : props.iconLeft
})

const rightSideIcon = computed(() => {
  if (props.variant === 'circle' && props.withAnimation) {
    return {
      ...props.iconDimensions,
      name: 'arrow',
    }
  }

  else if (!props.iconRight && !props.noLinkIcon && isExternalLink.value) {
    return {
      ...props.iconDimensions,
      name: 'external-link',
    }
  }

  else if (typeof props.iconRight === 'string') {
    return {
      ...props.iconDimensions,
      name: props.iconRight,
    }
  }
  else {
    return props.iconRight
  }
})

function handleClick(evt: MouseEvent) {
  if (props.disabled) { return }
  emit('click', evt)
}
// Classes are available in separate css file (btn.css)
const buttonClasses = computed(() => {
  const buttonLength = props.long ? 'btn--long' : 'btn--short'
  const buttonWidth = props.fullWidth ? 'btn--full-width' : ''
  let buttonHeight = 'btn--regular-height'

  if (props.slim) {
    buttonHeight = 'btn--slim-height'
  }

  if (props.verySlim) {
    buttonHeight = 'btn--very-slim-height'
  }

  switch (props.variant) {
    case 'primary':
      return `btn--primary ${buttonLength} ${buttonHeight} ${buttonWidth}`
    case 'secondary':
      return `btn--secondary ${buttonLength} ${buttonHeight} ${buttonWidth}`
    case 'transparent':
      return `btn--transparent ${buttonLength} ${buttonHeight} ${buttonWidth}`
    case 'action':
      return `btn--action ${buttonLength} ${buttonHeight} ${buttonWidth}`
    case 'link':
      return `btn--link`
    case 'download':
      return `btn--download`
    case 'circle':
      return 'btn--circle'
    case 'icon':
      return 'btn--icon'
    case 'white':
      return `btn--white ${buttonLength} ${buttonHeight} ${buttonWidth}`
    case 'blue':
      return `btn--blue ${buttonLength} ${buttonHeight} ${buttonWidth}`
    case 'cart':
      return `btn--cart`
    case 'text':
      return `btn--text`
    case 'outline':
      return `btn--outline ${buttonLength} ${buttonHeight} ${buttonWidth}`
    default:
      return `btn--primary ${buttonLength} ${buttonHeight} ${buttonWidth}`
  }
})

defineExpose({
  ref: uiHTMLButtonElement,
  variant: props.variant,
  disabled: props.disabled,
  to: props.to,
  isExternalLink,
})
</script>
