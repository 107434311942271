import type { Notification } from '@ui/composables/useNotificationsUi'

export enum NotificationComponentType {
  SYSTEM = 'system',
  TOAST = 'toast',
}

export interface Notifications extends Omit<Notification, 'message'> {
  messages: Notification[]
}
