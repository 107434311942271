interface Features {
  [key: string]: boolean
}

// TODO: Add TS types for options (after config files refactor)
export default function useConditionalRender(options?: { modules?: string[], flags?: string[] }) {
  const runtimeConfig = useRuntimeConfig()
  const appConfig = runtimeConfig.public.app

  // Check if all required modules modules are enabled
  const modulesSatisfied = computed(() => {
    if (!options?.modules?.length) { return true }
    return options.modules.every(item => appConfig.modules.includes(item))
  })

  // Check if all required feature flags are enabled
  const flagsSatisfied = computed(() => {
    if (!options?.flags?.length) { return true }
    return options.flags.every((feature) => {
      return _parseBoolean((appConfig.features as Features)[feature]) === true
    })
  })

  const isRenderAllowed = computed(() => modulesSatisfied.value && flagsSatisfied.value)
  const isEcommerceEnabled = computed(() => ['rawlplug-ecom', 'rawlplug-customer'].every(item => appConfig.modules.includes(item)))

  // Check if environment is global - use that in order to prevent creating multiple features when
  // simple things need to be checked
  const isGlobalEnvironment = computed(() => appConfig.region === 'global')

  return {
    isRenderAllowed,
    modulesSatisfied,
    flagsSatisfied,
    isEcommerceEnabled,
    isGlobalEnvironment,
  }
}

/**
 * Parse "true", "false" from import.meta.env
 */
function _parseBoolean(value: any): any {
  return value === 'true' ? true : value === 'false' ? false : value
}
