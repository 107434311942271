import type { Config as DOMPurifyConfig } from 'dompurify'

export async function sanitizeHTML(html: string, options: Partial<DOMPurifyConfig> = {}) {
  if (!html) {
    return '' as unknown as TrustedHTML
  }
  if (import.meta.server && !globalThis.__JSDOM__) {
    const { JSDOM } = await import('jsdom')
    globalThis.__JSDOM__ = new JSDOM('<!DOCTYPE html>')
  }
  if (!globalThis.__DOMPurify__) {
    const { default: createDOMPurify } = await import('dompurify')
    globalThis.__DOMPurify__ = createDOMPurify(
      import.meta.server ? globalThis.__JSDOM__?.window : window,
    )

    // Allow `target` attribute for anchor tags in a safe context
    globalThis.__DOMPurify__.addHook('afterSanitizeAttributes', (node) => {
      if (node.tagName === 'A' && node.getAttribute('target') === '_blank') {
        node.setAttribute('rel', 'noopener noreferrer')
      }
    })
  }

  return globalThis.__DOMPurify__.sanitize(html, {
    ...options,
    RETURN_TRUSTED_TYPE: true,
    ADD_ATTR: ['target', 'rel'],
  })
}
