function useMagentoCategoryProductsSlider() {
  const productsSlider = ref(null)
  const slider = ref(null)
  let observer: IntersectionObserver | null

  onMounted(() => {
    const isSafari = productsSlider.value?.swiper?.browser?.isSafari ?? false

    if (typeof window !== 'undefined' && isSafari) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            const items = entry.target.querySelectorAll('[data-nuxt-img]')
            items.forEach(item => item.setAttribute('loading', 'eager'))
          }
        },
      )

      if (slider.value) { observer.observe(slider.value) }
    }
  })

  onBeforeUnmount(() => {
    observer?.unobserve(slider.value)
  })

  return {
    productsSlider,
    slider,
  }
}

export { useMagentoCategoryProductsSlider }
