import { useAppNav } from '@base/components/AppNav/useAppNav'

/**
 * This middleware is used to handle Magento 2 redirects for product and category pages
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()

  // skip middleware on initial client load
  if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) { return }

  if (to.meta.name === 'category' || to.meta.name === 'product') {
    // skip middleware if already redirected
    if (!to.redirectedFrom) {
      const magentoSlug = Array.isArray(to.params.slug)
        ? to.params.slug.join('/')
        : to.params.slug

      if (magentoSlug) {
        const data = await GqlRoute({ url: magentoSlug })
        // if page not exist in Magento, show 404 page
        if (data?.route === null) {
          return showError({
            statusCode: 404,
          })
        }

        const { categoryLink, productLink } = useAppNav()
        const redirectCode = data?.route?.redirect_code
        const url = data?.route?.relative_url ?? to.fullPath
        if (redirectCode && to.meta.name === 'category') {
          return navigateTo(categoryLink(url), { redirectCode })
        }
        if (redirectCode && to.meta.name === 'product') {
          return navigateTo(productLink(url), { redirectCode })
        }
      }
      else {
        return navigateTo('/')
      }
    }
  }
})
