/* eslint-disable unused-imports/no-unused-vars */
import mapboxgl from 'mapbox-gl'
import type { WhereToBuyMapMapboxOptions } from './WhereToBuyMapbox.types'

export class MapBox {
  private mapBoxOptions: WhereToBuyMapMapboxOptions

  constructor(options: WhereToBuyMapMapboxOptions) {
    this.mapBoxOptions = options
  }

  setOptions(options: any) {
    this.mapBoxOptions = options
  }

  map = null

  addArea(geoJsonUrl) {

  }

  findLocation(location, callback) {

  }

  fitBounds(bounds) {

  }

  init(element: { id: any }) {
    mapboxgl.accessToken = 'pk.eyJ1IjoicmF3bHBsdWciLCJhIjoiY2xuaXRpc3U2MWlrdTJscmw4dzJqNHpzcCJ9.VNonLg_FCSgy0BnUoREzzg'
    this.map = new mapboxgl.Map({
      container: element.id,
      style: 'mapbox://styles/rawlplug/clumn1mk1002901mpheel1247',
      zoom: 1,
      center: [0, 0],
      scrollZoom: false,
      dragPan: false,
      dragRotate: false,
      interactive: false,
      touchZoomRotate: false,
      projection: {
        name: 'naturalEarth',
      },
    })
    this.invokeOnLoadedMap(() => this.initColorMap())
  }

  isPointInBounds(point) {

  }

  onDragEnd(callback) {

  }

  onLoaded(callback) {
    this.invokeOnLoadedMap(callback)
  }

  setStartingPosition(position: any) {

  }

  setCenter(center) {

  }

  setMarkers(points: any) {
    this.invokeOnLoadedMap(() => {
      const mapBoxPoints = points.map((point: { address: any, lng: any, lat: any }) => ({
        type: 'Feature',
        properties: {
          description: `<p>${point.address} </p>`,
        },
        geometry: {
          type: 'Point',
          coordinates: [point.lng, point.lat],
        },
      }))
      const source = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: mapBoxPoints,
        },
      }
      const layer = {
        id: 'objects',
        type: 'circle',
        source: 'objects',
        paint: {
          'circle-color': this.mapBoxOptions.markers.style.find((element: any[]) => this.mapBoxOptions.currentFilter === element.filter).paint['circle-color'],
          'circle-radius': 6,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff',
        },
      }

      const objectsLayer = this.map.getLayer('objects')
      if (objectsLayer) {
        this.map.removeLayer('objects')
      }
      const objectsSource = this.map.getSource('objects')
      if (objectsSource) {
        this.map.removeSource('objects')
      }

      this.map.addSource('objects', source)
      this.map.addLayer(layer)

      // const popup = new mapboxgl.Popup({
      //   closeButton: false,
      //   closeOnClick: false,
      // })

      // this.map.on('mouseenter', 'objects', (e) => {
      //     this.map.getCanvas().style.cursor = 'pointer';
      //     const coordinates = e.features[0].geometry.coordinates.slice();
      //     const description = e.features[0].properties.description;
      //     while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      //         coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      //     }
      //     popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
      // });

      // this.map.on('mouseleave', 'objects', () => {
      //     this.map.getCanvas().style.cursor = '';
      //     popup.remove();
      // });
    })
  }

  setPolygonStyle(styles: any) {

  }

  setZoom(zoom: number) {
    this.map?.setZoom(zoom)
  }

  invokeOnLoadedMap(callback: { (): void, (): void, (): void }) {
    if (this.map._loaded) {
      callback()
    }
    else {
      this.map.on('load', callback)
    }
  }

  initColorMap() {
    this.map.addSource('countries', {
      type: 'vector',
      url: 'mapbox://mapbox.country-boundaries-v1',
    })

    const getColor = () => {
      const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']]
      for (const row of this.mapBoxOptions.hightlightedCountries) {
        matchExpression.push(row.code, '#60a6ec')
      }
      // console.log(this.mapBoxOptions.excludeCountries)
      for (const row of this.mapBoxOptions.excludeCountries) {
        matchExpression.push(row.code, 'rgba(0,0,0,0)')
      }
      matchExpression.push('#003366')
      return matchExpression
    }

    this.map.addLayer(
      {
        'id': 'countries-join',
        'type': 'fill',
        'source': 'countries',
        'source-layer': 'country_boundaries',
        'paint': {
          'fill-color': getColor(),
        },
      },
      'admin-1-boundary-bg',
    )
  }
}
