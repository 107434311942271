import type { CustomerSubject } from '~/modules/rawlplug-customer/types/acl'

export interface RolePermission {
  permission_code: CustomerSubject
  scope: AccessTypeKey
}

type RolePermissionMap = Record<CustomerSubject, AccessTypeKey>

export const SystemRoleCodes = [
  'administrator',
  'manager',
] as const
type SystemRoleCode = typeof SystemRoleCodes[number]
type RoleCode = SystemRoleCode & string | NonNullable<unknown>

function getPermissionsFromMap(permissionMap: Partial<RolePermissionMap>): Array<RolePermission> {
  return Object.entries(permissionMap).map(([code, access]) => ({
    permission_code: code as CustomerSubject,
    scope: access,
  }))
}

export const DEFAULT_PERMISSIONS = getPermissionsFromMap({
  graphql_company: ACCESS_TYPE_KEY.READ_ONLY,
  graphql_customer_company_address: ACCESS_TYPE_KEY.READ_ONLY,
  graphql_company_permission_list: ACCESS_TYPE_KEY.READ_ONLY,
})

export function getRolePermissionsWithDefaults(permissions: Array<RolePermission>) {
  const defaults = DEFAULT_PERMISSIONS.filter(({ permission_code }) => {
    const permission = permissions.find(permission => permission.permission_code === permission_code)
    return !permission || permission.scope === ACCESS_TYPE_KEY.FORBIDDEN
  })

  return [
    ...defaults,
    ...permissions.filter(({ permission_code }) => !defaults.find(permission => permission.permission_code === permission_code)),
  ]
}

export const VISIBLE_PERMISSIONS: Array<CustomerSubject> = [
  'graphql_customer',
  'graphql_customer_addresses',
  'graphql_customer_orders',
  'graphql_customer_role',
  'graphql_receivables_and_trade_limit',
]

/**
 * Customer assigned to any of protected roles will become
 * ineditable and unremovable using the customer panel.
 *
 * Protected roles won't appear in role select in customer form.
 */
export const PROTECTED_ROLES: Array<RoleCode> = [
  'administrator',
]

/**
 * Manager can create users only within the company he is assigned to.
 */
export const RESTRICTED_UNIT_ROLES: Array<RoleCode> = [
  'manager',
]

export function isProtectedRole(roleCode: string | null | undefined) {
  if (!roleCode) { return false }
  return PROTECTED_ROLES.includes(roleCode)
}
