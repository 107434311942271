import routerOptions0 from "/home/ecomadm/app/releases/58/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.24.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/ecomadm/app/releases/58/modules/rawlplug-base/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}