import type { AnalyticsEvent } from '../types/plugins/analytics'

export default defineNuxtPlugin((nuxtApp) => {
  const { hook } = nuxtApp

  const PIWIK_ID = nuxtApp.$config?.public?.piwik?.id as string | undefined
  const PIWIK_HOST = nuxtApp.$config?.public?.piwik?.host as string | undefined

  if (!PIWIK_ID || !PIWIK_HOST) { return }

  if (import.meta.client) {
    window.dataLayer = window.dataLayer || []
  }

  useHead({
    link: [
      {
        rel: 'dns-prefetch',
        href: PIWIK_HOST,
        key: 'dns-prefetch-piwik',
      },
    ],
    script: [
      {
        key: 'piwik',
        innerHTML: `(function(window, document, dataLayerName, id) {
window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString();f="; SameSite=Strict"}document.cookie=a+"="+b+d+f+"; path=/"}
var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
tags.async=!0,tags.src="${PIWIK_HOST}/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
!function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
})(window, document, 'dataLayer', '${PIWIK_ID}');`,
      },
    ],
  })

  hook('analytics', event => sendAnalyticsEvent(event))

  hook('customer:login', (payload) => {
    provideUsercomId()
    sendAnalyticsEvent({
      event: 'login',
      ecommerce: {
        user_id: payload.email,
      },
    })
  })

  hook('customer:logout', (payload) => {
    if (payload?.userInitializedAction) {
      provideUsercomId()
      sendAnalyticsEvent({ event: 'logout' })
    }
  })

  hook('ecom:cart-add', (payload) => {
    sendAnalyticsEvent({
      event: 'add_to_cart',
      ecommerce: {
        item: payload.sku,
        quantity: payload.quantity,
      },
    })
  })

  /**
   * Initializes and manages the usercom ID in the dataLayer.
   *
   * The provideUsercomId function is fired on 'customer:login' and 'customer:logout'
   * events and updates the usercom ID in the
   * dataLayer based on the user's login status. The user ID is retrieved
   * from the customer data if logged in, and set to null if not.
   */
  if (import.meta.client) {
    provideUsercomId()
  }

  /**
   * usercom support
   */
  function provideUsercomId() {
    const { isLoggedIn, customerData } = useCustomer()
    const userId = isLoggedIn.value ? customerData.value?.usercom.user_id : null

    if (Array.isArray(window.dataLayer)) {
      return window.dataLayer.push({
        user_id: userId,
      })
    }
  }

  /**
   * Send event to dataLayer
   * @param {object} event - analytics event and optional data
   */
  function sendAnalyticsEvent(event: AnalyticsEvent) {
    if (window.dataLayer) {
      window.dataLayer.push(event)
    }
  }
})
