import { type ComputedRef, computed } from 'vue'
import type { AppDrawer } from '../components/AppDrawer/AppDrawer.types'
import type { AppHeader } from '../components/AppHeader/AppHeader.types'

export interface UiState {
  drawer: AppDrawer
  navDrawer: AppDrawer
  header: AppHeader
  [key: string]: any
}
/**
 * Get page data state
 * @returns {Ref<T3Page>}
 */

// Definition:
//  const drawer = addComponent<AppDrawer>('appDrawer', {
//   open: false,
//   position: 'left',
// })
// Usage:
// const { component } = useUiState()
// const drawer = component<AppDrawer>('appDrawer')
// drawer.value.open = true

export function uiState() {
  const uiState = useState<UiState>('ui', () => ({
    drawer: {
      open: false,
      data: {},
    },
    navDrawer: {
      open: false,
      position: 'left',
      component: 'AppNav',
    },
    header: {
      headerHeight: 0,
      isSticky: false,
      offset: 0,
    },
  }))
  return uiState
}

export function useUiState() {
  const state = uiState()

  const addComponent = <T>(key: string, value: T): ComputedRef<T> => {
    state.value[key] = value
    return component(key)
  }

  const component = <T>(key: string): ComputedRef<T> => {
    return computed(() => state.value[key])
  }

  return {
    state,
    addComponent,
    component,
  }
}
