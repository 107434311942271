import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import {
  DynamicScroller,
  DynamicScrollerItem,
  RecycleScroller,
} from 'vue-virtual-scroller'

// https://github.com/Akryum/vue-virtual-scroller/issues/832#issuecomment-1771665848
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('RecycleScroller', RecycleScroller)
  nuxtApp.vueApp.component('DynamicScroller', DynamicScroller)
  nuxtApp.vueApp.component('DynamicScrollerItem', DynamicScrollerItem)
})
