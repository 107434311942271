export const ACCESS_TYPE_KEY = {
  FULL: 'full_access',
  READ_ONLY: 'read_only_access',
  FORBIDDEN: 'no_access',
} as const

export type AccessTypeKey =
  (typeof ACCESS_TYPE_KEY)[keyof typeof ACCESS_TYPE_KEY]

export const ACCESS_TYPE_SCOPE: Record<AccessTypeKey, string> = {
  [ACCESS_TYPE_KEY.FULL]: 'create|read|update|delete',
  [ACCESS_TYPE_KEY.READ_ONLY]: 'read',
  [ACCESS_TYPE_KEY.FORBIDDEN]: 'null',
} as const

export function arePermissionAccessTypesSame(scopeA: string, scopeB: string) {
  const partA = scopeA.split('|').sort().join('|')
  const partB = scopeB.split('|').sort().join('|')
  return partA === partB
}

export function getAccessTypeKeyByScope(scope: string) {
  const [accessTypeKey] = Object.entries(ACCESS_TYPE_SCOPE).find(
    ([_, definedScope]) => arePermissionAccessTypesSame(definedScope, scope),
  ) ?? [ACCESS_TYPE_KEY.FORBIDDEN]

  return accessTypeKey as AccessTypeKey
}
