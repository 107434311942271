import { SortOrder, type UseSort } from './useSort.types'
import type { SortFields } from '#gql'

export function useSort(): UseSort {
  const app = useNuxtApp()
  const { $i18n } = app
  const { t } = $i18n
  const route = useRoute()

  const DefaultSortOption = {
    label: t('ecom_default'),
    value: '',
  }

  const SortByDefaults = () => ({ selected: DefaultSortOption, options: [] })

  /**
   * Created ascending and descending versions of given filters
   *  (i.e. if name field is provided, it creates ascending and descending
   *  version of that option)
   */
  const createSortOptions = (sortFields: SortFields) => {
    if (!sortFields || sortFields == null) {
      return SortByDefaults()
    }

    const sortQuery
      = typeof route.query.sort === 'string'
        ? route.query.sort
        : route.query.sort?.[0] ?? ''

    const sortOptions: { label: string, value: string }[] = [DefaultSortOption]

    Object.values(SortOrder).forEach((orderItem) => {
      const optionLabel = t((orderItem === 'asc') ? 'ascending' : 'descending')

      sortFields.options?.forEach((option) => {
        sortOptions.push({
          label: `${option?.label} - ${optionLabel}`,
          /* sort order value needs to be uppercase */
          value: `${option?.value}_${orderItem.toUpperCase()}`,
        })
      })
    })

    const selectedSortOption
      = sortOptions.find(option => option.value === sortQuery)
      ?? DefaultSortOption

    return {
      selected: selectedSortOption,
      options: sortOptions.sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    }
  }

  /**
   * Function that takes parameters that we want to sort by
   * and returns suitable Magento 2 sort input -
   * price_asc becomes { price: 'asc' }
   */
  const createProductAttributeSortInput = (sortString: string) => {
    const lastUnderscoreIndex = sortString.lastIndexOf('_')
    if (lastUnderscoreIndex === -1) { return {} }

    const key = sortString.substr(0, lastUnderscoreIndex)
    const value = sortString.substr(lastUnderscoreIndex + 1)

    return { [key]: value }
  }

  return {
    SortByDefaults,
    createSortOptions,
    createProductAttributeSortInput,
  }
}
