import { storeToRefs } from 'pinia'
import { useStoreConfigStore } from '../../stores/storeConfig'

function useStoreConfig() {
  const storeConfigStore = useStoreConfigStore()

  const {
    storeConfigData,
    centralOfficeData,
    customerServiceData,
    currencyData,
    checkoutData,
  } = storeToRefs(storeConfigStore)
  const { getStoreConfig } = storeConfigStore

  return {
    storeConfigData,
    centralOfficeData,
    customerServiceData,
    currencyData,
    checkoutData,
    getStoreConfig,
  }
}

export { useStoreConfig }
