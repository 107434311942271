export enum CustomerRoutes {
  ACCOUNT = '/account',
  PROFILE = '/account/profile',
  COMPANY = '/account/company',
  ADDRESSES = '/account/addresses',
  ORDERS = '/account/orders',
  LIST = '/account/lists',
  PAYMENTS_AND_BILLING = '/account/payments-and-billing',
  CONTACT = '/account/contact',
  PERMISSIONS_USERS = '/account/permissions/users',
}
