import type { App, InjectionKey, Plugin, Ref } from 'vue'
import { useMediaQuery as useMQ } from '@vueuse/core'

export interface RawlplugUiMq {
  /** (min-width: 480px) */
  isXs: Ref<boolean>
  /** (min-width: 640px) */
  isSm: Ref<boolean>
  /** (min-width: 768px) */
  isMd: Ref<boolean>
  /** (max-width: 1023px) */
  isMobile: Ref<boolean>
  /** (min-width: 1024px */
  isDesktop: Ref<boolean>
}

export const RawlplugUiMqKey: InjectionKey<RawlplugUiMq> = Symbol('mq')
export const RawlplugUiPlugin: Plugin = {
  install: (app: App) => {
    const mq = {
      isXs: useMQ('(min-width: 480px)'),
      isSm: useMQ('(min-width: 640px)'),
      isMd: useMQ('(min-width: 768px)'),
      isMobile: useMQ('(max-width: 1023px)'),
      isDesktop: useMQ('(min-width: 1024px)'),
    }
    app.provide('mq', mq) // this is fallback for non typescript usage eg. storybook
    app.provide(RawlplugUiMqKey, mq)
  },
}
