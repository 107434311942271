import type {
  ChangePasswordMutationVariables,
  CompanyInput,
  CompanyUserListQueryVariables,
  CreateCompanyCustomerMutationVariables,
  CreateCompanyCustomersMutationVariables,
  CreateCustomerAddressMutationVariables,
  CreatePasswordMutationVariables,
  CreateRoleMutationVariables,
  CustomerAddressesListQueryVariables,
  CustomerDataQueryVariables,
  CustomerRecentOrdersQueryVariables,
  DeleteCustomerAddressMutationVariables,
  DeleteCustomerByIdMutationVariables,
  DeleteRoleMutationVariables,
  GetCompaniesMutationVariables,
  GetCompanyUserByIdQueryVariables,
  GetCustomerOrderQueryVariables,
  GetPermissionListMutationVariables,
  GetRolesListMutationVariables,
  GetRolesMutationVariables,
  PasswordResetMutationVariables,
  RawlplugSetConsentsMutationVariables,
  ReceivablesListQueryVariables,
  ReorderItemsUserMutationVariables,
  SetCompanyOnCustomerMutationVariables,
  SetCustomerCompaniesMutationVariables,
  UpdateCompanyInput,
  UpdateCustomerAddressMutationVariables,
  ValidateCustomersFromCsvMutationVariables,
} from '#gql'

function useCustomerQueries() {
  const updateCustomerAddress = async (variables: UpdateCustomerAddressMutationVariables) =>
    await GqlUpdateCustomerAddress(variables)

  const createCustomerAddress = async (variables: CreateCustomerAddressMutationVariables) =>
    await GqlCreateCustomerAddress(variables)

  const rawlplugSetConsents = async (variables: RawlplugSetConsentsMutationVariables) =>
    await GqlRawlplugSetConsents(variables)

  const getCompanies = async (variables?: GetCompaniesMutationVariables) =>
    await GqlGetCompanies(variables)

  const createCompany = async (variables: CompanyInput) =>
    await GqlCreateCompany({ input: variables })

  const updateCompany = async (variables: UpdateCompanyInput) =>
    await GqlUpdateCompany({ input: variables })

  const getRoles = async (variables?: GetRolesMutationVariables) =>
    await GqlGetRoles(variables)

  const getRolesList = async (variables?: GetRolesListMutationVariables) =>
    await GqlGetRolesList(variables)

  const getPermissionList = async (variables?: GetPermissionListMutationVariables) =>
    await GqlGetPermissionList(variables)

  const createRole = async (variables: CreateRoleMutationVariables) =>
    await GqlCreateRole(variables)

  const deleteRole = async (variables: DeleteRoleMutationVariables) =>
    await GqlDeleteRole(variables)

  const customerData = async (variables?: CustomerDataQueryVariables) =>
    await GqlCustomerData(variables)

  const deleteCustomerById = async (variables: DeleteCustomerByIdMutationVariables) =>
    await GqlDeleteCustomerById(variables)

  const setCustomerCompanies = async (variables: SetCustomerCompaniesMutationVariables) =>
    await GqlSetCustomerCompanies(variables)

  const createCompanyCustomer = async (variables: CreateCompanyCustomerMutationVariables) =>
    await GqlCreateCompanyCustomer(variables)

  const createCompanyCustomers = async (variables: CreateCompanyCustomersMutationVariables) =>
    await GqlCreateCompanyCustomers(variables)

  const createPassword = async (variables: CreatePasswordMutationVariables) =>
    await GqlCreatePassword(variables)

  const passwordReset = async (variables: PasswordResetMutationVariables) =>
    await GqlPasswordReset(variables)

  const changePassword = async (variables: ChangePasswordMutationVariables) =>
    await GqlChangePassword(variables)

  const customerRecentOrders = async (variables?: CustomerRecentOrdersQueryVariables) =>
    await GqlCustomerRecentOrders(variables)

  const getCustomerOrder = async (variables: GetCustomerOrderQueryVariables) =>
    await GqlGetCustomerOrder(variables)

  const receivablesList = async (variables: ReceivablesListQueryVariables) =>
    await GqlReceivablesList(variables)

  const setCompanyOnCustomer = async (variables: SetCompanyOnCustomerMutationVariables) =>
    await GqlSetCompanyOnCustomer(variables)

  const deleteCustomerAddress = async (variables: DeleteCustomerAddressMutationVariables) =>
    await GqlDeleteCustomerAddress(variables)

  const reorderItemsUser = async (variables: ReorderItemsUserMutationVariables) =>
    await GqlReorderItemsUser(variables)

  const companyUserList = async (variables: CompanyUserListQueryVariables) =>
    await GqlCompanyUserList(variables)

  const getCompanyUserById = async (variables: GetCompanyUserByIdQueryVariables) =>
    await GqlGetCompanyUserById(variables)

  const getCustomerAddressesList = async (variables: CustomerAddressesListQueryVariables) =>
    await GqlCustomerAddressesList(variables)

  const validateCustomersFromCsv = async (variables: ValidateCustomersFromCsvMutationVariables) =>
    await GqlValidateCustomersFromCsv(variables)

  return {
    updateCustomerAddress,
    createCustomerAddress,
    rawlplugSetConsents,
    getCompanies,
    createCompany,
    updateCompany,
    getRoles,
    getRolesList,
    getPermissionList,
    createRole,
    deleteRole,
    customerData,
    deleteCustomerById,
    createCompanyCustomer,
    createCompanyCustomers,
    setCustomerCompanies,
    customerRecentOrders,
    getCustomerOrder,
    receivablesList,
    setCompanyOnCustomer,
    deleteCustomerAddress,
    reorderItemsUser,
    companyUserList,
    createPassword,
    passwordReset,
    changePassword,
    getCompanyUserById,
    getCustomerAddressesList,
    validateCustomersFromCsv,
  }
}

export { useCustomerQueries }
