export default function YouTubeEmbedOverlay() {
  return `
  <!-- play button -->
  <span class="hover:bg-grey-300 absolute scale-50 md:scale-100 top-1/2 left-1/2 flex justify-center items-center w-36 h-36 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white text-orange isolate" style="box-shadow: 0px 0px 13px 0px rgba(0, 51, 102, 0.25);">
    <span class="ml-3">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 52 60" width="52" height="60">
        <path fill="currentColor" d="M52.5 30L0 60V0L52.5 30Z"/>
      </svg>
    </span>
  </span>
`
}
