import { Can, abilitiesPlugin } from '@casl/vue'
import { defineAbility } from '@casl/ability'
import { useCustomerAcl } from '@customer/composables/useCustomerAcl'

export const abilities = defineAbility((_can, cannot) => {
  cannot('all', 'all')
})

export default defineNuxtPlugin((nuxtApp) => {
  const { customerData } = useCustomer()
  const { updateAbility } = useCustomerAcl()
  nuxtApp.vueApp
    .use(abilitiesPlugin, abilities, {
      useGlobalProperties: true,
    })
    .component('CustomerCan', Can)
  updateAbility(customerData.value?.acl)
})
