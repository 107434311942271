import { useAppNav } from '../components/AppNav/useAppNav'

/**
 * This middleware is responsible for refreshing navigation on client side
 * when layout changed from checkout to default.
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) { return }

  if (from.meta.layout === 'checkout' && to.meta.layout === 'default') {
    const { refreshNavigation } = useAppNav()
    const { getPathWithLocale } = useT3i18n()
    const { initialData, getInitialData } = useT3Api(getPathWithLocale())
    initialData.value = await getInitialData()
    await refreshNavigation(initialData?.value?.navigation)
  }
})
