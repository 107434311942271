import { useProductQueries } from '@ecom/composables/product/useProductQueries/useProductQueries'
import { mapProductsAttributesList } from '@ecom/helpers/products'
import type { PromotionProductsQueryProductsItems, PromotionProductsQueryProductsItemsItem } from '@ecom/types/types'
import type { T3CeRawlplugPromotionDetail } from './T3CeRawlplugpromotion_detail.types'
import { PROMOTION_DEFAULT_THEME, PROMOTION_THEME_MAP, T3CeRawlplugPromotionType } from './T3CeRawlplugpromotion_detail.types'
import type { PromotionProductsQueryVariables } from '#gql'

export function usePromotionDetail(data: ComputedRef<T3CeRawlplugPromotionDetail | null>) {
  const { t } = useI18n()

  const promotionTheme = computed(() => {
    return PROMOTION_THEME_MAP?.[data?.value?.type] ?? PROMOTION_DEFAULT_THEME
  })

  const promotionDetailsItems = computed(() => {
    return data?.value?.promoDetails?.related?.items ?? []
  })

  const promotionDetails = computed(() => {
    const details = data?.value?.promoDetails
    const promoDetails = {
      promotionType: details?.related?.type ?? '',
      promotionTitle: details?.value ?? '',
      promotionDescription: details?.description ?? '',
      promotionCode: details?.code ?? '',
      promotionCopyCodeText: details?.copyText ?? '',
      promotionListLink: details?.link?.href ?? null,
      background: {
        desktop: details?.background?.desktop,
        mobile: details?.background?.mobile,
      },
    }

    if (promoDetails.promotionType === 'category') {
      promoDetails.showProductList = promotionDetailsItems.value?.[0]?.showProductList ?? false
    }

    return promoDetails
  })

  const promotionProductsSkus = computed(() => {
    if (data?.value?.promoDetails?.related?.type === 'products') {
      const products = promotionDetailsItems.value

      if (products.length) {
        const productsSkus = products.map((product) => {
          return product?.productLink?.magentoProduct?.sku ?? null
        }).filter(item => item)

        return productsSkus
      }
    }
    return []
  })

  async function getPromotionItemListProducts(variables: PromotionProductsQueryVariables) {
    const attributesList = [
      'tax_class_id',
    ]

    return await GqlPromotionProducts({
      ...variables,
      attributes: mapProductsAttributesList(attributesList),
    })
  }

  async function getPromotionProductListProducts(
    mode: 'byCategory' | 'bySkus',
    input: string | string[],
    currentPage: number = 1,
    pageSize: number = 12,
  ) {
    const { getCatalogProducts } = useProductQueries()
    const attributesList = [
      'labels_ecommerce',
      'brand',
      'product_pos_color',
      'ecommerce_packing_type',
      'tax_class_id',
      'oryginal_unit',
      'erp_packaging_and_quantity',
      'certificates',
    ]

    const filters = {
      type_id: {
        in: ['simple'],
      },
    }

    if (mode === 'byCategory' && typeof input === 'string') {
      filters.category_uid = {
        in: [input],
      }
    }
    else if (mode === 'bySkus' && Array.isArray(input)) {
      filters.sku = {
        in: input,
      }
    }

    return await getCatalogProducts({
      filters,
      attributes: mapProductsAttributesList(attributesList),
      pageSize,
      currentPage,
    })
  }

  function mergePromotionProductsItemsData(products: PromotionProductsQueryProductsItems) {
    const mergedProductData = products?.map((product: PromotionProductsQueryProductsItemsItem) => {
      const typo3Product = promotionDetailsItems.value?.filter((item) => {
        return item?.productLink?.magentoProduct?.sku === product.sku
      })
      const typo3ProductsData = {
        amount: typo3Product?.[0]?.amount ?? 0,
      }

      if (typo3Product?.[0]?.title) {
        typo3ProductsData.name = typo3Product[0].title
      }

      if (typo3Product?.[0]?.image) {
        typo3ProductsData.image = typo3Product[0].image
      }

      return {
        ...product,
        ...typo3ProductsData,
      }
    }) || []

    return mergedProductData.sort((a, b) => {
      return promotionProductsSkus.value.indexOf(a.sku) - promotionProductsSkus.value.indexOf(b.sku)
    })
  }

  const promotionItemsCategory = computed(() => {
    if (data.value?.promoDetails?.related?.type === 'category' && data.value?.type !== T3CeRawlplugPromotionType.OUTLET) {
      const category = promotionDetailsItems.value?.[0]
      return [{
        image: category?.image ?? null,
        name: category?.title ?? '',
        categoryId: category?.magentoLink?.magentoCategory?.uid ?? null,
        buttonText: category?.buttontext ?? t('details'),
        buttonLink: category?.magentoLink?.href ?? '',
        promotionNote: category?.promoNote ?? '',
      }]
    }
    return null
  })

  const promotionRelatedItems = computed(() => {
    return data?.value?.related ?? []
  })

  const promotionSteps = computed(() => {
    const steps = data?.value?.steps ?? {}
    return [
      steps?.first ?? null,
      steps?.second ?? null,
      steps?.third ?? null,
    ].filter(step => step).map((stepContent) => {
      return { content: stepContent }
    })
  })

  const promotionTerms = computed(() => {
    const terms = data?.value?.terms
    return {
      header: terms?.header ?? '',
      url: terms?.file?.publicUrl ?? '',
      name: terms?.fileTitle ?? '',
      description: terms?.textBelow ?? '',
    }
  })

  return {
    promotionTheme,
    promotionDetails,
    promotionProductsSkus,
    getPromotionItemListProducts,
    getPromotionProductListProducts,
    mergePromotionProductsItemsData,
    promotionItemsCategory,
    promotionRelatedItems,
    promotionSteps,
    promotionTerms,
  }
}
