import { confirmed, max, required } from '@vee-validate/rules'
import { defineRule } from 'vee-validate'

export function getSingleParam<TParam = unknown>(params: [TParam] | Record<string, TParam>, paramName: string) {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export default defineNuxtPlugin({
  name: 'vee-validate',
  parallel: true,
  async setup({ $i18n }) {
    const { t } = $i18n

    /**
     * redefine default required rule with custom message
     * https://github.com/logaretm/vee-validate/blob/main/packages/rules/src/required.ts
     */
    defineRule('required', (value: unknown): boolean | string => {
      if (!required(value)) {
        return t('validation.required')
      }
      return true
    })

    /**
     * redefine default max rule with custom message
     * https://github.com/logaretm/vee-validate/blob/main/packages/rules/src/max.ts
     */
    defineRule('max', (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
      const length = getSingleParam(params, 'length')
      if (!max(value, { length })) {
        return t('validation.max', { length })
      }
      return true
    })

    /**
     * redefine default confirmed rule with custom message
     * https://github.com/logaretm/vee-validate/blob/main/packages/rules/src/confirmed.ts
     */
    defineRule('confirmed', (value: unknown, params: [string] | { target: string }) => {
      if (!confirmed(value, params)) {
        return t('validation.password_mismatch')
      }
      return true
    })
  },
})
