/**
 * This plugin is responsible for preventing user actions on arleady laoded FE app when the app is in maintenance mode.
 * It listens for 503 status code in responses from Magento 2 and TYPO3 API and reloads the page if it's detected.
 * After hard reload in browser, nginx will serve maintenance page.
 */
export default defineNuxtPlugin({
  name: 'maintenance-mode',
  parallel: true,
  async setup(nuxtApp) {
    const { hook } = nuxtApp

    hook('t3:onResponse', context => verifyMaintanceMode(context.response.status))
    hook('ecom:rest:onResponse', context => verifyMaintanceMode(context.response.status))
    hook('ecom:graphql:onError', error => verifyMaintanceMode(error.statusCode))

    function verifyMaintanceMode(statusCode: number | undefined) {
      if (statusCode === 503) {
        return window.location.reload()
      }
    }
  },
})
