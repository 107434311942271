import type { Money } from '#gql'

export function mapProductsAttributesList(attributes: string[]) {
  return attributes.map((item) => {
    return {
      attribute_code: item,
    }
  })
}

export function sanitizeM2Money(money?: Money | null): Money {
  return {
    value: money?.value ?? null,
    currency: money?.currency ?? '',
  } as Money
}
