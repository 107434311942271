import { SectionDataDefaultSections } from '@customer/stores/customer'
import { useCustomer } from '@customer/composables/useCustomer'

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) { return }

  const { isLoggedIn, refreshSectionData } = useCustomer()
  if (!isLoggedIn.value || to.name === 'ecom-redirect-resolve') { return }

  const requestedSectionData = (to.meta?.sectionData as []) ?? []
  const sections = [
    ...new Set([...SectionDataDefaultSections, ...requestedSectionData]),
  ]
  refreshSectionData({
    sections,
    lazy: true,
  })
})
