import { computed, useRouter, useRuntimeConfig } from '#imports'

export default function useStorefrontData() {
  const isDefinedStorefront = computed(() => {
    const { currentRoute } = useRouter()
    const { storefronts } = useRuntimeConfig().public
    const slugLangCodes = storefronts ? Object.keys(storefronts) : []

    return slugLangCodes?.some((slug: string): boolean => {
      return currentRoute.value.path?.includes(slug)
    })
  })

  return { isDefinedStorefront }
}
