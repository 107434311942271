import { storeToRefs } from 'pinia'
import type {
  Notification,
  NotificationId,
} from '@ui/composables/useNotificationsUi'
import { useNotificationsStore } from '../stores/notifications'
import type { NotificationComponentType } from '../stores/notifications.types'

export function useNotification(componentType: `${NotificationComponentType}` = 'toast' as NotificationComponentType) {
  const store = useNotificationsStore()
  const { systemNotifications, toastMessages } = storeToRefs(store)
  const {
    showNotification: _showNotification,
    closeNotification: _closeNotification,
    notificationComponentTypes,
    clearToastMessages,
    clearSystemNotifications,
  } = store

  let resolvedNotificationComponentType: NotificationComponentType
    = componentType as NotificationComponentType

  if (!notificationComponentTypes.includes(resolvedNotificationComponentType)) {
    resolvedNotificationComponentType = 'toast' as NotificationComponentType

    console.warn(
      'Provided component type is incorrect. Pass appropriate value based on "NotificationComponentType" interface. Fallback to "toast"',
    )
  }

  const showNotification = (notificationObject: Notification) => {
    _showNotification(notificationObject, resolvedNotificationComponentType)
  }

  const closeNotification = (id: NotificationId) => {
    _closeNotification(id, resolvedNotificationComponentType)
  }

  return {
    showNotification,
    closeNotification,
    systemNotifications,
    toastMessages,
    clearToastMessages,
    clearSystemNotifications,
  }
}
