import type { HeadSafe } from '@unhead/vue'
import { CheckoutRoutes } from '@ecom/types/checkout.types'
import { SearchRouteName } from '@ecom/stores/search'
import { useMagentoData } from '../composables/useMagentoData/useMagentoData'

/**
 * Fetch Magento page data for category, product and search pages.
 * Data comes from TYPO3 API, which aggregates hreflangs and promotions.
 */
export default defineNuxtPlugin(async (nuxtApp) => {
  const { hook } = nuxtApp

  const { getMagentoData } = useMagentoData()

  hook('ecom:page:loading:end', async ({ route, magentoPageData }) => {
    if (!['category', 'product', SearchRouteName.STORE, CheckoutRoutes.CART].includes(route.meta.name)) {
      return
    }
    const { hreflangsData, error } = await getMagentoData({ page: route.meta.name, pageData: magentoPageData })

    if (error.value) {
      console.error('Error fetching Magento page data:', error)
      hreflangs.value = []
      return
    }

    hreflangs.value = hreflangsData
  })

  const hreflangs = ref<HeadSafe['link']>([])
  useHead({ link: hreflangs })
})
