// this is overrided Nuxt version
// https://github.com/nuxt/nuxt/blob/7998dac5416e5e4968d9c3d05e766353e618a066/packages/nuxt/src/pages/runtime/router.options.ts#L1
import { nextTick } from 'vue'
import type { RouterConfig } from 'nuxt/schema'
import { type RuntimeNuxtHooks, useNuxtApp } from '#app/nuxt'
import type { RouteLocationNormalized, RouterScrollBehavior } from '#vue-router'

const scrollToTopByDefault = true
function shouldScrollToTop(route: RouteLocationNormalized) {
  if (scrollToTopByDefault) {
    return route.meta.scrollToTop !== false
  }
  return !!route.meta.scrollToTop
}

type ScrollPosition = Awaited<ReturnType<RouterScrollBehavior>>

// Default router options
// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    const nuxtApp = useNuxtApp()
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    const header = __NUXT__?.state?.$sui?.header
    let position: ScrollPosition = savedPosition || undefined

    // If there is a hash, scroll to the hash or top of page
    if (to.hash && to.hash !== '') {
      if (to.hash === '#prevent-scroll') {
        return Promise.resolve(false)
      }
      return new Promise(resolve =>
        setTimeout(
          () => {
            resolve({
              el: to.hash,
              top:
                header?.headerHeight + header?.offset
                || _getHashElementScrollMarginTop(to.hash),
              behavior: 'smooth',
            })
          },
          !from || to.path !== from.path ? 500 : 1,
        ),
      )
    }

    if (
      (!position || (position && shouldScrollToTop(to)))
      && from
      && to
      && _isDifferentRoute(from, to)
    ) {
      position = { left: 0, top: 0 }
    }

    const hasTransition = (route: RouteLocationNormalized) =>
      !!(route.meta.pageTransition)

    let hookToWait: keyof RuntimeNuxtHooks
      = hasTransition(from) && hasTransition(to)
        ? 'page:transition:finish'
        : 'page:loading:end'

    if (!_isDifferentRoute(from, to)) {
      hookToWait = 'page:loading:start'
    }

    return new Promise((resolve) => {
      nuxtApp.hooks.hookOnce(hookToWait, async () => {
        await nextTick()
        if (to.hash) {
          position = {
            el: to.hash,
            top: _getHashElementScrollMarginTop(to.hash),
          }
        }
        resolve(position || { top: 0, behavior: 'instant' })
      })
    })
  },
}

function _getHashElementScrollMarginTop(selector: string): number {
  try {
    const elem = document.querySelector(selector)
    if (elem) {
      return Number.parseFloat(getComputedStyle(elem).scrollMarginTop)
    }
  }
  catch {}
  return 0
}

function _isDifferentRoute(
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
): boolean {
  const samePageComponent = to.matched.every(
    (comp, index) =>
      comp.components?.default === from.matched[index]?.components?.default,
  )
  if (!samePageComponent) {
    return true
  }

  const hasEqualParams = JSON.stringify(from.params) === JSON.stringify(to.params)
  const hasEqualQuery = JSON.stringify(from.query) === JSON.stringify(to.query)

  return (
    samePageComponent && (!hasEqualParams || !hasEqualQuery)
  )
}
