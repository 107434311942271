<template>
  <div
    data-test="error-page"
    class="relative w-full overflow-y-auto"
  >
    <img
      :src="props.background.desktop.src"
      alt=""
      :width="props.background.desktop.width"
      :height="props.background.desktop.height"
      loading="lazy"
      class="absolute z-0 hidden size-full object-cover object-center md:block"
    >
    <img
      :src="props.background.mobile.src"
      alt=""
      :width="props.background.mobile.width"
      :height="props.background.mobile.height"
      loading="lazy"
      class="absolute z-0 size-full object-cover object-center md:hidden"
    >
    <div
      class="relative z-10 grid h-screen place-content-center gap-4 p-4 text-center"
    >
      <img
        v-if="props.showLogo"
        :src="publicDir('/logo.svg')"
        width="290"
        height="30"
        class="mx-auto mb-2 brightness-0 invert"
      >
      <hr
        v-if="props.showLogo"
        class="w-full bg-white"
      >
      <img
        v-if="props.errorImage"
        :src="props.errorImage.src"
        alt=""
        :width="props.errorImage.width"
        :height="props.errorImage.height"
        class="mx-auto"
      >
      <h1 class="text-xl font-normal text-white">
        {{ props.errorMessage }}
      </h1>
      <p
        v-if="props.errorMesssageDescription"
        class="whitespace-pre-line text-center text-white"
      >
        {{ props.errorMesssageDescription }}
      </p>
      <div class="flex w-full flex-wrap justify-center gap-5">
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
export interface ErrorImage {
  src: string
  width: number
  height: number
}
export interface BackgroundImages {
  desktop: ErrorImage
  mobile: ErrorImage
}

const props = withDefaults(
  defineProps<{
    errorMessage: string
    errorMesssageDescription?: string
    errorImage?: ErrorImage | undefined
    background: BackgroundImages
    showLogo?: boolean
    layout?: 'default' | 'full-screen'
  }>(),
  {
    errorMesssageDescription: '',
    showLogo: false,
    errorImage: undefined,
    layout: 'default',
  },
)
</script>
