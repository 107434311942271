import type { PageSizeOptionsUnion } from './usePageSize.types'
import type { CookieRef } from '#app'

const PageSizeOptions = [9, 18, 24, 48, 69, 96] as const
const DefaultPageSize = PageSizeOptions[0] || 9

function usePageSize() {
  const pageSizeCookie: CookieRef<PageSizeOptionsUnion>
    = useStatefullCookie('pageSize')

  /**
   * Returns page size value
   * @returns {QueryParams}
   */

  const getPageSizeValue = (pageSize: number) => {
    if (!pageSize || !PageSizeOptions.includes(pageSize)) {
      return pageSizeCookie.value || DefaultPageSize
    }

    return pageSize
  }

  const changePageSizeCookie = (pageSize: number) => {
    if (!pageSize) {
      return
    }
    pageSizeCookie.value = pageSize
  }

  return {
    PageSizeOptions,
    DefaultPageSize,
    getPageSizeValue,
    changePageSizeCookie,
  }
}

export { PageSizeOptions, DefaultPageSize, usePageSize }
