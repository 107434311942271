export function parseCmsBlockContent(content: string) {
  if (!content) {
    return null
  }
  let contentHolder = content
  if (contentHolder.startsWith('<p>')) {
    contentHolder = contentHolder.slice(3)
  }
  if (contentHolder.endsWith('</p>')) {
    contentHolder = contentHolder.slice(0, -4)
  }
  contentHolder = contentHolder.replace(/&quot;/g, '"')

  try {
    return JSON.parse(contentHolder)
  }
  catch (e) {
    console.error(e)
    return null
  }
}
