export function useCheckbox() {
  const isIndeterminateState = (
    checkedItems: string[],
    childrenLength: number,
  ): boolean => {
    if (!checkedItems || !Array.isArray(checkedItems)) {
      return false
    }
    const checkedCount = checkedItems.length
    return checkedCount > 0 && checkedCount < childrenLength
  }

  return {
    isIndeterminateState,
  }
}
