import { useStorage } from '@vueuse/core'
import type { MaybeRefOrGetter, RemovableRef, StorageLike, UseStorageOptions } from '@vueuse/core'
import { useCookie } from '#imports'
import type { CookieOptions, CookieRef } from '#app'

/**
 * Wrapped useStorage from @vueuse/core to add regional prefix to the given key.
 * useStorage docs: {@link https://vueuse.org/core/useStorage/}
 */
export function useRegionalStorage<T extends(string | number | boolean | object | null)>(
  key: string,
  defaults: MaybeRefOrGetter<T>,
  storage?: StorageLike | undefined,
  options: UseStorageOptions<T> = {},
): RemovableRef<T> {
  const regionalName = regionalStorageName(key)
  return useStorage(regionalName, defaults, storage, options)
}

/**
 * Wrapped useCookie from nuxt to add regional prefix to cookie name.
 * useCookie docs: {@link https://nuxt.com/docs/api/composables/use-cookie}
 */
export function useRegionalCookie<T = string | null | undefined>(
  name: string,
  _opts?: CookieOptions<T>,
): CookieRef<T> {
  const cookieName = regionalStorageName(name)
  const options: CookieOptions = { sameSite: 'lax', ..._opts }
  if (_opts?.readonly === true) {
    return useCookie(cookieName, { ...options, readonly: true })
  }
  else {
    return useCookie(cookieName, { ...options, readonly: false })
  }
}

export function regionalStorageName(name: string) {
  const region = useRuntimeConfig()?.public?.app?.region
  if (!region && typeof region !== 'string') {
    throw new Error('Region is not defined in runtime config.')
  }
  return `${region}-${name}`
}
