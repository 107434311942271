import type { M2CheckoutAddresses } from '@ecom/components/checkout/M2CheckoutAddresses/M2CheckoutAddresses.types'
import { useUiState } from '../../composables/useUiState'
import type { AppDrawer } from './AppDrawer.types'

export function useDrawer() {
  const { component } = useUiState()
  const drawer = component<AppDrawer>('drawer')
  /**
   * AppNavDrawer UiState is defined separately from other drawers because it is rendered separately
   * for SEO purposes (links should always be visible in page source)
   */
  const navDrawer = component<AppDrawer>('navDrawer')

  const toggleDrawer = (options: AppDrawer) => {
    drawer.value.open = options.open || !drawer.value.open
    drawer.value.component = options.component
    drawer.value.position = options.position
    drawer.value.data = options.data
    drawer.value.props = options.props
    drawer.value.key = options.key
  }

  const toggleAppNav = (
    parentLink?: string,
    onMobile = false,
    clickOrigin = 'default',
  ) => {
    navDrawer.value.open = !navDrawer.value.open
    navDrawer.value.position = onMobile ? 'bottom' : 'left'
    navDrawer.value.data = {
      parentLink,
      clickOrigin,
    }
    navDrawer.value.key = parentLink
  }

  const toggleAppRegions = () => {
    toggleDrawer({
      open: !drawer.value.open,
      component: 'AppRegions',
      props: {
        width: 'w-full xl:w-3/4',
      },
    })
  }

  const toggleAppAccount = () => {
    toggleDrawer({
      open: !drawer.value.open,
      component: 'AccountSidebar',
      position: 'right',
      props: {
        width: 'w-full xl:w-4/12',
      },
    })
  }

  const toggleSearchOverlay = () => {
    toggleDrawer({
      open: !drawer.value.open,
      component: 'AppSearchOverlay',
      position: 'bottom',
      props: {
        width: 'w-full',
        height: 'h-full',
      },
    })
  }

  const toggleAppCheckoutAddresses = (data: M2CheckoutAddresses) => {
    toggleDrawer({
      open: !drawer.value.open,
      component: 'AppCheckoutAddresses',
      key: data?.selected?.id,
      data,
      position: 'left',
      props: {
        width: 'w-full',
        height: 'h-full',
      },
    })
  }

  const toggleAppAccountChangePass = () => {
    toggleDrawer({
      open: !drawer.value.open,
      component: 'AccountChangePass',
      position: 'right',
      props: {
        width: 'w-full xl:w-4/12',
      },
    })
  }

  return {
    component,
    drawer,
    navDrawer,
    toggleDrawer,
    toggleAppNav,
    toggleAppRegions,
    toggleAppAccount,
    toggleSearchOverlay,
    toggleAppCheckoutAddresses,
    toggleAppAccountChangePass,
  }
}
