export default defineNuxtPlugin(async (nuxtApp) => {
  const { vueApp } = nuxtApp
  const { public: { sentry } } = useRuntimeConfig()

  if (sentry?.enabled) {
    const { init, attachErrorHandler, browserTracingIntegration } = await import('@sentry/vue')
    const router = useRouter()
    init({
      app: vueApp,
      dsn: sentry.dsn,
      environment: import.meta.env.ENV_CONFIG,
      sampleRate: Number(sentry.sampleRate),
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
      ],
      logErrors: import.meta.env.ENV_CONFIG === 'development',
      integrations: [browserTracingIntegration({ router })],
    })

    attachErrorHandler(vueApp, {
      logErrors: true,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['create', 'mount', 'update'],
    })
  }
})
