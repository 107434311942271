import { initForEditing, prepareIsCheckedStatus, prepareScenarioWithState } from '@rawlplug/consents-state/consents-service'
import replaceConsentVariables from '@base/utils/replaceConsentVariable'

type ConsentsService = Awaited<ReturnType<typeof initForEditing>>
type ConsentsScenarioType = ReturnType<ConsentsService['consents']>

export type RawlplugCustomerConsent = RawlplugCustomerConsentBase & {
  subConsentMinimum: number
  children: Array<RawlplugCustomerConsentBase>
}

export interface RawlplugCustomerConsentBase {
  consentId: string
  pathId: string
  parentId: string | null
  versionId: string
  isRequired: number
  canWithdraw: boolean
  code: string
  token: string
  name: string
  lang: string
  description: string
  moreDescription: string
}

export default function useCustomerConsentsManagement(customerEmail: Ref<string>, currentUserConsents: string[]) {
  const consentsForView = ref<RawlplugCustomerConsent[]>([])
  const consentsStateForView = ref<Record<string, boolean>>({})
  let consentsService: ConsentsService
  let initialized = false
  let consentsAdapter: ReturnType<typeof createConsentsAdapter>
  const validationStatus = ref<ReturnType<ConsentsService['validate']>>({ correct: true, results: new Map() })
  const { currentLocale } = useT3i18n()
  const { t } = useI18n()
  const { customerToken } = useCustomer()
  const config = useRuntimeConfig()
  const { siteUrl, rid, storefronts } = config.public
  const ridApi = rid.api
  const currentStore = storefronts[currentLocale.value as keyof typeof storefronts]

  async function getToken(customerToken: string) {
    const response = await $fetch<{
      token: string
    }>(`${ridApi}user/prepare-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        token: customerToken,
        domain: siteUrl,
        store: currentStore,
      }),
    })

    return response.token
  }

  async function init() {
    if (initialized) { return }
    initialized = true
    getToken(customerToken.value ?? '')
      .then((userToken) => {
        return getConsentsServiceAndState(userToken, currentUserConsents, currentLocale.value)
      })
      .then((service) => {
        consentsService = service
        consentsAdapter = createConsentsAdapter(consentsService)
        consentsForView.value = consentsAdapter.getConsentsForView()
        consentsStateForView.value = consentsAdapter.getConsentsStateForView()
      })
      .catch((error) => {
        console.error('Error while fetching consents', error)
      })
  }

  function isConsentActive(consentId: string) {
    return consentsStateForView.value[consentId] ?? false
  }

  function selectConsent(value: boolean, consent: RawlplugCustomerConsentBase) {
    if (!consent.consentId) { return }
    consentsAdapter.selectConsent(value, consent.consentId)
    consentsStateForView.value = consentsAdapter.getConsentsStateForView()
  }

  async function send() {
    const model = consentsService.prepareModel(customerEmail.value, 'External-user-connection', currentLocale.value)
    const preparedModel = {
      ...model,
      approveConsents: Object.keys(model.approveConsents).reduce<Record<string, number>>((acc, key) => {
        if (model.approveConsents[key]) {
          const keyWithoutToken = key.split(':').pop() ?? key
          acc[keyWithoutToken] = model.approveConsents[key]
        }
        return acc
      }, {}),
    }

    return $fetch(`${ridApi}approve/consents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(preparedModel),
    })
      .then((response) => {
        initialized = false
        init()

        return response
      })
  }

  function mapResultErrorsMessage(results: ReturnType<ConsentsService['validate']>) {
    const errorsMap = new Map([
      ['errorRequired', t('validation.required')],
      ['errorMinimumSubConsents', t('validation.minimum_sub_consents')],
    ])

    results.results.forEach((result) => {
      if (result.errors.length) {
        result.errors = result.errors.map(error => errorsMap.get(error) ?? error)
      }
    })
  }

  function validate() {
    const results = consentsAdapter.validate()
    mapResultErrorsMessage(results)
    validationStatus.value = results

    return results
  }

  return {
    isConsentActive,
    selectConsent,
    validate,
    validationStatus,
    consentsForView,
    consentsStateForView,
    init,
    send,
  }
}

function createConsentsAdapter(consentsService: ConsentsService) {
  const consents = mapConsentFromRid(consentsService.consents() as ConsentsScenarioType)
  const consentsState = consentsService.getConsentState()
  const consentsGroupedById = groupBy(consents, 'consentId')
  const consentsIdToPathIdMap = createConsentsIdToPathIdMap(consents)
  const consentsForView = createConsentsForView(consentsGroupedById)
  let consentsStateForView = createConsentsViewState(consentsForView)

  function createConsentsIdToPathIdMap(consents: RawlplugCustomerConsent[]) {
    return consents.reduce<Map<string, string[]>>((acc, consent) => {
      if (consent.consentId) {
        acc.set(consent.consentId, (acc.get(consent.consentId) ?? []).concat([consent.pathId]))
      }
      if (consent.children) {
        consent.children.forEach((childConsent) => {
          if (childConsent?.consentId) {
            acc.set(childConsent.consentId, (acc.get(childConsent.consentId) ?? []).concat([childConsent.pathId]))
          }
        })
      }
      return acc
    }, new Map<string, string[]>())
  }

  function createConsentsForView(consents: Record<string, RawlplugCustomerConsent[]>): RawlplugCustomerConsent[] {
    const { consentsOrder, consentsMap } = Object.entries(consents)
      .reduce(
        (acc, entries) => {
          const [, consents] = entries

          consents.forEach((consent) => {
            const consentCopy = JSON.parse(JSON.stringify(consent)) as RawlplugCustomerConsent
            consentCopy.description = replaceConsentVariables(consentCopy.description)

            if (consentCopy.consentId) {
              if (acc.consentsMap.has(consentCopy.consentId)) {
                const existingConsent = acc.consentsMap.get(consentCopy.consentId)!

                const children = existingConsent.children ?? []
                consentCopy.children?.forEach((childConsent) => {
                  childConsent.description = replaceConsentVariables(childConsent.description)
                  if (childConsent) {
                    const existingChildConsent = children?.find(child => child?.consentId === childConsent.consentId)
                    if (existingChildConsent) {
                      const newChildConsent = {
                        ...existingChildConsent,
                        ...childConsent,
                        canWithdraw: existingConsent.canWithdraw && consentCopy.canWithdraw,
                        isRequired: +(!!existingChildConsent?.isRequired || !!childConsent.isRequired),
                      }
                      children.splice(children.indexOf(existingChildConsent), 1, newChildConsent)
                    }
                    else {
                      children.push(childConsent)
                    }
                  }
                })

                const newConsent: RawlplugCustomerConsent = {
                  ...existingConsent,
                  ...consentCopy,
                  canWithdraw: existingConsent.canWithdraw && consentCopy.canWithdraw,
                  subConsentMinimum: Math.max(existingConsent.subConsentMinimum ?? 0, consentCopy.subConsentMinimum ?? 0),
                  isRequired: +(!!existingConsent?.isRequired || !!consentCopy.isRequired),
                  children,
                }
                acc.consentsMap.set(consentCopy.consentId, newConsent)
              }
              else {
                acc.consentsMap.set(consentCopy.consentId, consentCopy)
                acc.consentsOrder.push(consentCopy.consentId)
              }
            }
          })

          return acc
        },
        {
          consentsOrder: <string[]>[],
          consentsMap: new Map<string, RawlplugCustomerConsent>(),
        },
      )

    return consentsOrder.map(consentId => consentsMap.get(consentId)!)
  }

  function checkConsentsInStateByConsentId(value: boolean, consentId: string) {
    consentsIdToPathIdMap
      .get(consentId)
      ?.forEach((pathId) => {
        consentsService.checkConsent(pathId, value)
      })
  }

  function updateConsentsStateForView() {
    consentsStateForView = createConsentsViewState(consentsForView)
  }

  function selectConsent(value: boolean, consentId: string) {
    checkConsentsInStateByConsentId(value, consentId)
    updateConsentsStateForView()
  }

  function createConsentsViewState(consentsForView: RawlplugCustomerConsent[]): Record<string, boolean> {
    function isAnyConsentActive(consentId: string) {
      const pathIds = consentsIdToPathIdMap.get(consentId) ?? []
      const consentsStates = getStateForPathIds(pathIds)

      return consentsStates.some(consentState => !!consentState?.isChecked)
    }

    function getStateForPathIds(pathIds: string[]) {
      if (!pathIds && !consentsState) {
        return []
      }
      return pathIds.map(pathId => consentsState.state.get(pathId))
    }

    return consentsForView.reduce<Record<string, any>>((acc, consent) => {
      if (consent && typeof consent.consentId === 'string') {
        acc[consent.consentId] = isAnyConsentActive(consent.consentId)

        consent.children?.forEach((childConsent) => {
          if (childConsent?.consentId) {
            acc[`${childConsent?.consentId}`] = isAnyConsentActive(childConsent.consentId)
          }
        })
      }
      return acc
    }, {})
  }

  function getConsentsStateForView() {
    return { ...consentsStateForView }
  }

  function getConsentsForView() {
    return JSON.parse(JSON.stringify(consentsForView))
  }

  function validate() {
    function mergeResults(results: ReturnType<ConsentsService['validate']>): ReturnType<ConsentsService['validate']> {
      const newResults = {
        correct: results.correct,
        results: new Map(),
      }

      results.results.forEach((value, key) => {
        const keywithoutToken = key.split(':').pop() ?? key
        const preparedKey = keywithoutToken.split('_').pop() ?? keywithoutToken
        const existingValue = newResults.results.get(preparedKey)
        const deleteDuplication = (array: string[]) => [...new Set(array)]

        if (existingValue) {
          newResults.results.set(preparedKey, {
            consentPathID: preparedKey,
            correct: existingValue.correct && value.correct,
            errors: deleteDuplication([...existingValue.errors, ...value.errors]),
          })
        }
        else {
          newResults.results.set(preparedKey, value)
        }
      })

      return newResults
    }

    Object.entries(consentsStateForView).forEach((consent) => {
      const [key, value] = consent
      checkConsentsInStateByConsentId(value, key)
    })
    updateConsentsStateForView()

    return mergeResults(consentsService.validate())
  }

  return {
    validate,
    selectConsent,
    getConsentsForView,
    getConsentsStateForView,
  }
}

async function getConsentsServiceAndState(userToken: string, currentUserConsents: string[], language: string) {
  const editingServiceParams = {
    scenarioToken: userToken,
    userToken,
    language,
  }
  const { scenario, state } = await prepareScenarioWithState(editingServiceParams, 'fetchConsentsScenariosByUser')
  const stateWithIsChecked = prepareIsCheckedStatus(state)
  const scenarioFiltered = scenario.filter(scenario => currentUserConsents.includes(String(scenario.consentID)))
  const pathIDs = scenarioFiltered.flatMap(scenario => [scenario.pathID, ...scenario.subConsents?.consents.map(child => child.pathID) ?? []])
  const stateFiltered: typeof stateWithIsChecked = new Map()
  stateWithIsChecked.forEach((item, key) => {
    if (pathIDs.includes(key)) {
      stateFiltered.set(key, item)
    }
  })

  return initForEditing(scenarioFiltered, stateFiltered)
}

function mapConsentFromRid(consents: ConsentsScenarioType): RawlplugCustomerConsent[] {
  return consents?.map((consent) => {
    return {
      pathId: String(consent.pathID),
      consentId: String(consent.consentID),
      versionId: String(consent.ID),
      parentId: consent.parentID ? String(consent.parentID) : null,
      name: consent.name,
      code: consent.code,
      token: (consent as any).token,
      description: consent.description,
      moreDescription: (consent as any).moreDescription,
      canWithdraw: consent.canWithdraw,
      isRequired: Number(consent.isRequired),
      lang: consent.language,
      subConsentMinimum: consent?.subConsents?.minimum ?? 0,
      children: consent.subConsents?.consents.map((child) => {
        return {
          pathId: `${consent.pathID}_${child.consentID}`,
          consentId: String(child.consentID),
          versionId: String(child.ID),
          parentId: child.parentID ? String(child.parentID) : null,
          name: child.name,
          code: child.code,
          token: (child as any).token,
          description: child.description,
          moreDescription: (child as any).moreDescription,
          canWithdraw: child.canWithdraw,
          isRequired: Number(child.isRequired),
          lang: child?.language,
        }
      }) ?? [],
    }
  })
}

function groupBy<T, K extends keyof T>(list: T[], key: K) {
  return list?.reduce((acc, cur) => {
    const keyValue = cur[key] as any;
    (acc[keyValue] = acc[keyValue] || []).push(cur)
    return acc
  }, {} as Record<any, T[]>) || null
}
