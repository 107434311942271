import { parse } from 'node-html-parser'
import type { T3CeBaseProps, T3ContentElement } from '@t3headless/nuxt-typo3'
import generateUrlHash from '@ui/utils/generateUrlHash'
import type { ElementToObserve } from '@ui/utils/useTableOfContentsObserver'
import type { T3Ce4ColsContainer } from '../T3Ce4colsContainer/T3Ce4colsContainer.types'
import type { T3CeRawlplugTwoColumnsContainer } from '../T3Ce2colsWithHeaderContainer/T3Ce2colsWithHeaderContainer.types'
import type { T3NewsDetail } from './T3CeNews_newsdetail.types'

/**
 * This is used to parse HTML provided by WISIWYG editor and extract all headings
 * with their ids (to generate table of contents for news detail page)
 * @param html - string
 * @returns ElementToObserve[]
 */
export function getHeadingsFromString(html: string): ElementToObserve[] {
  let parsedContent = null
  if (import.meta.server) {
    parsedContent = parse(html)
  }
  else {
    const parser = new DOMParser()
    parsedContent = parser.parseFromString(html, 'text/html')
  }

  const foundHeadings: ElementToObserve[] = []

  if (parsedContent) {
    const parsedHeadings = parsedContent.querySelectorAll(
      'h1, h2, h3, h4, h5, h6',
    )
    parsedHeadings.forEach((heading: HTMLElement) => {
      if (heading?.id && heading?.textContent) {
        foundHeadings.push({
          name: heading.textContent,
          id: heading.id,
        })
      }
    })
  }

  return foundHeadings
}

export function getContentElementHeadings(ce: T3ContentElement<T3CeBaseProps>): ElementToObserve[] {
  const headings: ElementToObserve[] = []

  if (ce.content?.header && 'subheader' in ce.content) {
    headings.push({
      name: ce.content.header,
      id: generateUrlHash(ce.content.header),
    })
  }

  if (ce.content?.bodytext) {
    headings.push(...getHeadingsFromString(ce.content?.bodytext))
  }

  return headings
}

export function getAllNewsHeadings(newsDetail: T3NewsDetail, contentElements: T3ContentElement<T3CeBaseProps>[]): ElementToObserve[] {
  const headings: ElementToObserve[] = []
  // get headings from article intro WYSIWYG text
  if (newsDetail?.bodytext) {
    headings.push(...getHeadingsFromString(newsDetail.bodytext))
  }

  // get headings from content elements
  contentElements?.forEach((ce: T3ContentElement<T3CeBaseProps>) => {
    // if content element is a two columns container, get headings from its children
    if (ce.type === 'rawlplug_two_columns_container') {
      const ce2Cols = ce.content as T3CeRawlplugTwoColumnsContainer
      const leftCol = ce2Cols?.left?.[0]
      const rightCol = ce2Cols?.right?.[0]
      if (leftCol) { headings.push(...getContentElementHeadings(leftCol)) }
      if (rightCol) { headings.push(...getContentElementHeadings(rightCol)) }
    }
    else if (ce.type === 'rawlplug_fourth_cols_container') {
      // if content element is a four columns container, get headings from its children
      const ce4Cols = ce.content as T3Ce4ColsContainer
      const firstCol = ce4Cols?.first?.[0]
      const secondCol = ce4Cols?.second?.[0]
      const thirdCol = ce4Cols?.third?.[0]
      const fourthCol = ce4Cols?.fourth?.[0]
      if (firstCol) { headings.push(...getContentElementHeadings(firstCol)) }
      if (secondCol) { headings.push(...getContentElementHeadings(secondCol)) }
      if (thirdCol) { headings.push(...getContentElementHeadings(secondCol)) }
      if (fourthCol) { headings.push(...getContentElementHeadings(secondCol)) }
    }
    else {
      // otherwise get headings from the content element itself
      headings.push(...getContentElementHeadings(ce))
    }
  })
  return headings
}

export default getAllNewsHeadings
