import { useCustomer } from '@customer/composables/useCustomer'
import { useNotification } from '@base/composables/useNotifications'
import { useCustomerStore } from '@customer/stores/customer'
import type { NotificationComponentType } from '@base/stores/notifications.types'
import type { CustomerSectionData } from '@customer/types/customerSectionData'

const CREDIT_HOLD_SYSTEM_NOTIFICATION = 'credit-hold-system'
const CREDIT_HOLD_TOAST_NOTIFICATION = 'credit-hold-toast'
const CREDIT_HOLD_CMS_BLOCK_ID = 'user-credit-hold'

function useCustomerCreditHold() {
  const { customerData } = useCustomer()
  const { $i18n } = useNuxtApp()
  const customerStore = useCustomerStore()
  const { refreshSectionData } = customerStore
  const message = `<span class="font-light">${$i18n.t(
    'ecom_your_purchase_ability_has_been_blocked_settle_outstanding_payments_and_contact_customer_service',
  )}</span>`

  const {
    showNotification: showSystemNotification,
    closeNotification: closeSystemNotification,
  } = useNotification('system' as NotificationComponentType)

  const { showNotification: showToastNotification } = useNotification(
    'toast' as NotificationComponentType,
  )

  const hasCustomerCreditHold = computed(() => {
    return customerData?.value?.['credit-hold']?.is_on_credit_hold || false
  })

  const toggleSystemNotificationOnCreditHold = () => {
    if (hasCustomerCreditHold.value) {
      return showSystemNotification({
        message,
        type: 'error',
        closable: false,
        id: CREDIT_HOLD_SYSTEM_NOTIFICATION,
      })
    }

    return closeSystemNotification(CREDIT_HOLD_SYSTEM_NOTIFICATION)
  }

  const toggleToastNotificationOnCreditHold = () => {
    if (hasCustomerCreditHold.value) {
      return showToastNotification({
        message,
        type: 'error',
        id: CREDIT_HOLD_TOAST_NOTIFICATION,
        timeout: 5000,
      })
    }
  }

  const verifyCreditHold = async (): Promise<CustomerSectionData | null> => {
    const sectionData = await refreshSectionData({
      sections: ['credit-hold'],
    })

    toggleSystemNotificationOnCreditHold()
    return sectionData
  }

  return {
    hasCustomerCreditHold,
    verifyCreditHold,
    toggleSystemNotificationOnCreditHold,
    toggleToastNotificationOnCreditHold,
    CREDIT_HOLD_CMS_BLOCK_ID,
  }
}

export { useCustomerCreditHold }
