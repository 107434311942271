import { useCatalogStore } from '@ecom/stores/catalog'
import type { MagentoPageData } from '@cms/types/MagentoPage.types'
import { SearchRouteName } from '@ecom/stores/search'
import { useSearch } from '@ecom/composables/useSearch/useSearch'
import { type CheckoutRoutes } from '@ecom/types/checkout.types'
import usePromotions from '@ecom/composables/usePromotions'

export interface MagentoDataOptions {
  categoryUID?: string[]
  productSKU?: string[]
  categoryCode?: string
}

function useMagentoData() {
  const { $fetch } = useT3Api()
  const { currentLocale } = useT3i18n()
  const { setPromotions } = usePromotions()

  async function getMagentoData(data?: { page: 'category' | 'product' | `${SearchRouteName.STORE}` | `${CheckoutRoutes.CART}`, pageData?: MagentoDataOptions }) {
    const options: MagentoDataOptions = {
      categoryUID: data?.pageData?.categoryUID ? data.pageData.categoryUID : [],
      productSKU: data?.pageData?.productSKU ? data.pageData.productSKU : [],
      categoryCode: data?.pageData?.categoryCode ? data.pageData.categoryCode : '',
    }

    /**
     * If we are on category/search page, we can use data from store.
     * On product page we are using data from pageData parameter passed on page load finish.
     */
    if (data?.page === 'category') {
      const { category, catalogProducts } = useCatalogStore()

      if (category?.uid) {
        options.categoryUID?.push(category.uid)
      }
      if (category?.code) {
        options.categoryCode = category.code
      }

      if (Array.isArray(catalogProducts?.items)) {
        catalogProducts.items.forEach((product) => {
          options.productSKU?.push(product?.sku || '')
        })
        options.productSKU = options.productSKU?.filter(sku => !!sku)
      }
    }

    // If we are on search page (ecom), we can use data from search store
    if (data?.page === SearchRouteName.STORE) {
      const { catalogProducts } = useSearch()
      if (Array.isArray(catalogProducts.value?.items)) {
        catalogProducts.value.items.forEach((product) => {
          options.productSKU?.push(product?.sku || '')
        })
        options.productSKU = options.productSKU?.filter(sku => !!sku)
      }
    }

    // The same key to avoid duplicate requests - 'dedupe' option is set by default
    // to 'cancel', so it cancels existing requests when a new one is made`,
    const { data: response, error } = await useAsyncData<MagentoPageData>(
      `magento-page-data`,
      () => $fetch<MagentoPageData>(`/${currentLocale.value}/rest/v1/magento/page`, {
        params: {
          cat: options.categoryUID?.join(',') || undefined,
          sku: options.productSKU?.join(',') || undefined,
          catc: options.categoryCode || undefined,
        },
      }),
    )

    const promotions = response.value?.promotions ?? []
    const hreflangs = response.value?.hreflangs ?? {}

    if (Array.isArray(promotions)) {
      setPromotions(promotions)
    }

    return {
      hreflangsData: Object.keys(hreflangs).map((langKey) => {
        if (!langKey || !response.value?.hreflangs[langKey]) {
          return null
        }
        return {
          rel: 'alternate',
          hreflang: langKey,
          href: response.value.hreflangs[langKey],
          hid: langKey,
        }
      }).filter(item => item !== null),
      promotionsData: promotions,
      error,
    }
  }

  return {
    getMagentoData,
  }
}

export { useMagentoData }
