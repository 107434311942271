export interface RegistrationPendingState {
  register: boolean
  businessIdentityTypes: boolean
  countriesList: boolean
  companyByTaxNumber: boolean
}

export enum RegistrationResponseStatusEnum {
  SUCCESS = '200',
  USER_ALREADY_EXISTS = '201',
  BAD_REQUEST = '400',
  CONFLICT = '409',
}

export type RegistrationResponseStatusType = `${RegistrationResponseStatusEnum}`

export type CurrentUserRegistrationProcessStatus =
  | false
  | `${RegistrationResponseStatusEnum}`

export interface RegistrationResponse {
  [key: string]: unknown
  status?: number
  message?: string
  data?: Record<string, unknown>
}

export interface RegistrationUserData {
  taxNumber: string
  taxNumberCountry: { value: string, label: string }
  payerName: string
  payerFullAddress: string
  payerCity: string
  payerPostcode: string
  payerCountry: { value: string, label: string }
  businessActivityDataSelectsValues: string[][]
  payerIndustry: string
  payerProfile: string
  authorisedPersonName: string
  authorisedPersonSurname: string
  authorisedPersonContactPhoneNumber: string
  authorisedPersonEmail: string
  url?: string // for analytics purposes
}

export interface BusinessIdentityTypes {
  channel_type: string
  specialization_type: string
}

export enum BusinessIdentityTypesEnum {
  CHANNEL = 'channel_type',
  SPECIALIZATION = 'specialization_type',
}

export interface BusinessIdentityProfileTypeValues {
  profile_code: string
  channel_type: string
}

export interface BusinessIdentityIndustryTypeValues {
  industry_code: string
  specialization_type: string
}

export interface BusinessIdentityProfileType {
  [key: string]: BusinessIdentityProfileTypeValues[]
}

export interface BusinessIdentityIndustryType {
  [key: string]: BusinessIdentityIndustryTypeValues[]
}

export interface BusinessIdentityType {
  profile?: BusinessIdentityProfileType | null
  industry?: BusinessIdentityIndustryType | null
}

export enum GUS_DICTIONARY {
  REGON = 'Regon',
  TAX_NUMBER = 'Nip',
  NAME = 'Nazwa',
  VOIVODESHIP = 'Wojewodztwo',
  COUNTY = 'Powiat',
  DISTRICT = 'Gmina',
  CITY = 'Miejscowosc',
  ZIP_CODE = 'KodPocztowy',
  STREET = 'Ulica',
  PROPERTY_NUMBER = 'NrNieruchomosci',
  TYPE = 'Typ ',
  SILOS_ID = 'SilosID',
  POST_OFFICE_CITY = 'MiejscowoscPoczty ',
}

export const GUS_REGISTER_FIELDS = {
  [GUS_DICTIONARY.NAME]: 'payerName',
  [GUS_DICTIONARY.STREET]: 'payerFullAddress',
  [GUS_DICTIONARY.CITY]: 'payerCity',
  [GUS_DICTIONARY.ZIP_CODE]: 'payerPostcode',
  payerCountry: 'payerCountry',
}

export const REGISTER_ROUTE_NAME = 'register'
