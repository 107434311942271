import { computed } from 'vue'

export interface UiHeaderLink {
  additionalAttributes?: string[]
  class?: string | null
  href?: string | null
  linkText?: string | null
  target?: string | null
  title?: string | null
}

export type UiHeaderColor =
  | 'color-dark-blue'
  | 'color-white'
  | ''

export interface UiHeaderProps {
  /**
   * Header content / TYPO3 header
   */
  header?: string
  /**
   * Header Type / TYPO3 header_layout
   */
  headerLayout?: number | string
  /**
   * Header Color / TYPO3 header_color
   */
  headerColor?: UiHeaderColor | null
  /**
   * Header alignment / TYPO3 header_position
   */
  headerPosition?: string
  /**
   * Header link / TYPO3 header_link
   */
  headerLink?: UiHeaderLink | string
  /**
   * Subheader / TYPO3 subheader
   */
  subheader?: string

  headerStyleClass?: string | string[] | null
  generateId?: boolean
}

/**
 * Provides headerLevel and headerClass computed values based on provided props
 * @param props
 */
export function useUiHeader(props: UiHeaderProps) {
  /**
   * By defualt if type is 0, set h1
   */
  const headerLevel = computed((): number => {
    if (Number(props.headerLayout) === 0) {
      return 1
    }
    else {
      return props.headerLayout === 'section-header'
        ? 2
        : Number(props.headerLayout)
    }
  })
  /**
   * Apply nuxt-typo3 default class name
   */
  const headerClass = computed((): string => {
    return props.headerPosition ? `ui-header--${props.headerPosition}` : ''
  })

  return { headerLevel, headerClass }
}
