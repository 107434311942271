import type { ReferenceObjectsType } from './ReferenceObjects.types'

export const referenceObjects: ReferenceObjectsType[] = [
  {
    name: 'burj-khalifa',
    text: 'Burj Khalifa',
    location: 'dubai',
    buildingImgPath: '/about-us-ldp/reference-objects/burj-khalifa/burj-khalifa.jpg',
    backgroudImgPath: '/about-us-ldp/reference-objects/burji-khalifa/burji-khalifa-background.jpg',
    previewImgPath: '/about-us-ldp/reference-objects/burj-khalifa/burj-khalifa-preview.jpg',
  },
  {
    name: 'wembley-stadium',
    text: 'wembley-stadium',
    location: 'great-britain',
    buildingImgPath: '/about-us-ldp/reference-objects/wembley-stadium/wembley-stadium.jpg',
    backgroudImgPath: '/about-us-ldp/reference-objects/wembley-stadium.jpg',
    previewImgPath: '/about-us-ldp/reference-objects/wembley-stadium/wembley-stadium-preview.jpg',

  },
  {
    name: 'opra-dubai',
    text: 'opra-dubai',
    location: 'ZEA',
    buildingImgPath: '/about-us-ldp/reference-objects/opra-dubai/opra-dubai.jpg',
    backgroudImgPath: '/about-us-ldp/reference-objects/opra-dubai-background.jpg',
    previewImgPath: '/about-us-ldp/reference-objects/opra-dubai/opra-dubai-preview.jpg',
  },
  {
    name: 'altra-tower',
    text: 'altra-tower',
    location: 'Srlinka',
    buildingImgPath: '/about-us-ldp/reference-objects/altra-tower/altra-tower.jpg',
    backgroudImgPath: '/about-us-ldp/reference-objects/altra-tower-background.jpg',
    previewImgPath: '/about-us-ldp/reference-objects/altra-tower/altra-tower-preview.jpg',
  },
  {
    name: 'nfm-wroclaw',
    text: 'nfm-wroclaw',
    location: 'Poland',
    buildingImgPath: '/about-us-ldp/reference-objects/nfm/nfm.jpg',
    backgroudImgPath: '/about-us-ldp/reference-objects/NFM-background.jpg',
    previewImgPath: '/about-us-ldp/reference-objects/nfm/nfm-preview.jpg',
  },
  {
    name: 'mars-rover',
    text: 'mars-rover',
    location: 'USA',
    buildingImgPath: '/about-us-ldp/reference-objects/mars-rover/mars-rover.jpg',
    backgroudImgPath: '/about-us-ldp/reference-objects/mars-rover-background.jpg',
    previewImgPath: '/about-us-ldp/reference-objects/mars-rover/mars-rover-preview.jpg',
  },
  {
    name: 'changi-airport',
    text: 'changi-airport',
    location: 'singapur',
    buildingImgPath: '/about-us-ldp/reference-objects/changi-airport/changi-airport.jpg',
    backgroudImgPath: '/about-us-ldp/reference-objects/changi-airport-background.jpg',
    previewImgPath: '/about-us-ldp/reference-objects/changi-airport/changi-airport-preview.jpg',
  },
  {
    name: 'jubilee-line',
    text: 'jubilee-line',
    location: 'great-britain',
    buildingImgPath: '/about-us-ldp/reference-objects/jubilee-line/jubilee-line.jpg',
    backgroudImgPath: '/about-us-ldp/reference-objects/jubilee-line-background.jpg',
    previewImgPath: '/about-us-ldp/reference-objects/jubilee-line/jubilee-line-preview.jpg',

  },
  {
    name: 'dubai-metro',
    text: 'dubai-metro',
    location: 'ZEA',
    buildingImgPath: '/about-us-ldp/reference-objects/dubai-metro/dubai-metro.jpg',
    backgroudImgPath: '/about-us-ldp/reference-objects/marina-bay-sands-background.jpg',
    previewImgPath: '/about-us-ldp/reference-objects/dubai-metro/dubai-metro-preview.jpg',
  },
  {
    name: 'hs2-line',
    text: 'hs2-line',
    location: 'great-britain',
    buildingImgPath: '/about-us-ldp/reference-objects/hs2-line/hs2-line.jpg',
    backgroudImgPath: '/about-us-ldp/reference-objects/marina-bay-sands-background.jpg',
    previewImgPath: '/about-us-ldp/reference-objects/hs2-line/hs2-line-preview.jpg',
  },
]
