import type { T3CeTable } from '@t3headless/nuxt-typo3'

export type BodyTextType = string[][]

export function useT3CeTable(props: T3CeTable) {
  const thead = computed((): string[] => {
    return (
      (props.tableHeaderPosition === 1
      && [...(props.bodytext as BodyTextType)].shift())
      || []
    )
  })

  const tbody = computed((): string[][] => {
    const tbody = [...(props.bodytext as BodyTextType)]
    if (thead?.value?.length) {
      tbody.shift()
    }
    if (tfoot?.value?.length) {
      tbody.pop()
    }
    return tbody
  })

  const tfoot = computed((): string[] => {
    return (
      (props.tableTfoot === '1'
      && [...(props.bodytext as BodyTextType)].pop())
      || []
    )
  })

  return { thead, tbody, tfoot }
}
