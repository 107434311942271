/**
 * This plugin allows to attach multiple handlers to @t3headless/nuxt-typo3 API interceptors by exposing them as Nuxt hooks.
 */
export default defineNuxtPlugin(async (nuxtApp) => {
  const { callHook } = nuxtApp
  const { setOption } = useT3Api()

  setOption('onRequest', context => callHook('t3:onRequest', context))
  setOption('onRequestError', context => callHook('t3:onRequestError', context))
  setOption('onResponse', context => callHook('t3:onResponse', context))
  setOption('onResponseError', context => callHook('t3:onResponseError', context))
})
