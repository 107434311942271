interface SliderElementType {
  url: string
  id: string
}

export const sliderElements: SliderElementType[] = [
  {

    url: './',
    id: 'slide-1887',
  },
  {
    url: './',
    id: 'slide-1911',
  },
  {
    url: './',
    id: 'slide-1919',
  },
  {
    url: './',
    id: 'slide-1926',
  },
  {
    url: './',
    id: 'slide-1930',
  },
  {
    url: './',
    id: 'slide-1934',
  },
  {
    url: './',
    id: 'slide-1948',
  },
  {
    url: './',
    id: 'slide-1982',
  },
  {
    url: './',
    id: 'slide-2005',
  },
  {
    url: './',
    id: 'slide-2009',
  },
  {
    url: './',
    id: 'slide-2011',
  },
  {
    url: './',
    id: 'slide-2012',
  },
  {
    url: './',
    id: 'slide-2013',
  },
  {
    url: './',
    id: 'slide-2019',
  },
  {
    url: './',
    id: 'slide-2020',
  },
  {
    url: './',
    id: 'slide-2021',
  },
  {
    url: './',
    id: 'slide-2022',
  },
  {
    url: './',
    id: 'slide-2023',
  },
]
