import { parsePath, parseQuery, withoutLeadingSlash } from 'ufo'

/**
 * Our app is multiregional, so we need to handle paths that are not in the current region.
 * Byy default nuxt will send request to TYPO3 API with regional slug as baseURL.
 * Here we check if requested URL first slug is different than current region and if so we send request to TYPO3 API without regional slug.
 */
export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.client) { return }

  const { $config } = useNuxtApp()
  const { currentSiteOptions } = useT3Options()

  const url = useRequestEvent()
  let requestedPath = ''

  // special case for malformed 404 request URL
  if (url.node.req.originalUrl?.includes('__nuxt_error')) {
    const parsedPath = parsePath(url.node.req.originalUrl)
    const params = parseQuery(parsedPath.search)
    requestedPath = params.url as string
  }
  else {
    requestedPath = url.node.req.originalUrl as string
  }

  const requestedPathSlugs = withoutLeadingSlash(parsePath(requestedPath).pathname).split('/')
  const region = $config.public?.app?.region ?? ''
  const langs = currentSiteOptions.value?.i18n?.locales ?? []

  const requestedPathRegion = requestedPathSlugs?.[0]
  const requestedPathLang = requestedPathSlugs?.[1]

  if (requestedPathRegion !== region || !langs.includes(requestedPathLang)) {
    const nonRegionAPI = currentSiteOptions.value?.api.baseUrl?.replace(`/${region}`, '')
    const response = await $fetch(`${nonRegionAPI}${requestedPath}`)
    if (response.redirectUrl && response.statusCode) {
      return navigateTo(response.redirectUrl, {
        redirectCode: response.statusCode,
        external: true,
      })
    }
  }
})
