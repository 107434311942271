import { useUiState } from '@base/composables/useUiState'

export function useReportErrorModal() {
  const { addComponent, component } = useUiState()
  addComponent('reportErrorModal', { visible: false })
  const reportErrorModal = component<{ visible: boolean }>('reportErrorModal')

  const toggleReportErrorModal = (options: { visible: boolean }) => {
    reportErrorModal.value.visible
      = options?.visible || !reportErrorModal.value.visible
  }

  return {
    reportErrorModal,
    toggleReportErrorModal,
  }
}
