import { useCustomer } from '@customer/composables/useCustomer'
import { useDrawer } from '@base/components/AppDrawer/useDrawer'

/**
 * Handle auth redirect query in URL, e.g. ?auth=/pl/account/profile
 */
export default defineNuxtPlugin(() => {
  const { isLoggedIn } = useCustomer()
  const { toggleAppAccount } = useDrawer()
  const { currentRoute } = useRouter()

  watch(currentRoute, async (newRoute, oldRoute) => {
    if (newRoute.path !== oldRoute?.path) { // prevent from firing twice
      const authUrl = currentRoute.value.query?.auth as string

      // if ?auth= and not logged in (client only)
      if (authUrl && !isLoggedIn.value && import.meta.client) {
        toggleAppAccount()
      }

      // if ?auth= and logged in, then redirect
      if (authUrl && isLoggedIn.value) {
        await navigateTo(authUrl)
      }
    }
  }, { immediate: true })
})
