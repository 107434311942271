import { joinURL } from 'ufo'

/**
 * This is workaround for the issue with public dir in nuxt if baseURL is set in app.
 * Every asset from /public need to be loaded via this function.
 * @see https://github.com/nuxt/nuxt/issues/22107
 * @see https://github.com/nuxt/nuxt/issues/19818
 * @example publicDir('/landing-page/bg.jpg')
 */
export default function publicDir(...path: string[]) {
  const app = useRuntimeConfig().app
  const publicBase = app.cdnURL || app.baseURL
  return path.length ? joinURL(publicBase, ...path) : publicBase
}
