export function findSelectOptionParentValues(data, targetValue: string, index: number, path: string[] = []): string[] | undefined {
  if (
    !Array.isArray(data)
    || !data?.length
    || !targetValue
    || typeof index !== 'number'
  ) {
    return
  }

  const item = data?.[index]

  if (!item) {
    return
  }

  if (item.options) {
    for (
      let optionIndex = 0;
      optionIndex < item.options.length;
      optionIndex++
    ) {
      const option = item.options[optionIndex]
      const newPath = [...path, option.value] as string[]

      if (option.value === targetValue) {
        return newPath
      }
      else if (option.children) {
        const result = findSelectOptionParentValues(
          option.children,
          targetValue,
          0, // Use the index of the first child option
          newPath,
        )
        if (result) {
          return result
        }
      }
    }
  }
}
