import type { StoreConfigQuery } from '#gql'

export function mapCurrentCurrencyData(storeConfig: NonNullable<StoreConfigQuery['currency']>) {
  const {
    base_currency_code: currencyCode = '',
    base_currency_symbol: currencySymbol = '',
    ...data
  } = storeConfig

  const currencyData = {
    currencyCode,
    currencySymbol,
  }

  return {
    ...data,
    currencyData,
  }
}
