import { useRegionalStorage } from '@base/composables/useRegionalStorage'
import { RawlplugUiMqKey } from '@ui/plugin'
import type { RawlplugUiMq } from '@ui/plugin'
import { DEFAULT_VARIANT } from '@ui/components/templates/product/ProductItemTemplate/ProductItemTemplate.types'
import { M2CategoryProductListViewEnum } from '@ecom/components/M2CategoryProducts/M2CategoryProducts.types'

/*
  USER - logged in user
  GUEST - not logged in visitor
  CUSTOMER - term used in pricing - individual data
 */
function useListViewSwitches() {
  const { isDesktop } = (inject(RawlplugUiMqKey) as RawlplugUiMq) || {}

  const listViewSwitches = ref({
    desktop: [
      {
        listViewType: M2CategoryProductListViewEnum.SMALL_TILE,
        icon: {
          name: 'grid',
          name_active: 'grid-solid',
          width: 19,
          height: 19,
        },
      },
      {
        listViewType: M2CategoryProductListViewEnum.BIG_TILE,
        icon: {
          name: 'list-big-tile',
          name_active: 'list-big-tile-solid',
          width: 19,
          height: 19,
        },
      },
      {
        listViewType: M2CategoryProductListViewEnum.SLIM_TILE,
        icon: {
          name: 'list-small-tile',
          name_active: 'list-small-tile-solid',
          width: 19,
          height: 19,
        },
      },
    ],
    mobile: [
      {
        listViewType: M2CategoryProductListViewEnum.SMALL_TILE,
        icon: {
          name: 'bigbox',
          width: 18,
          height: 25,
        },
      },
      {
        listViewType: M2CategoryProductListViewEnum.VERY_SMALL_TILE,
        icon: {
          name: 'smallbox-rule',
          width: 18,
          height: 25,
        },
      },
    ],
  })

  const selectedListView = useRegionalStorage(
    'catalog-list-view',
    {
      desktop: DEFAULT_VARIANT,
      mobile: DEFAULT_VARIANT,
    },
  )

  const clientSelectedListView = ref()
  // Prevent hydration mismatches

  watch(clientSelectedListView, (newValue) => {
    isDesktop.value ? selectedListView.value.desktop = newValue : selectedListView.value.mobile = newValue
  })
  watch(isDesktop, (newValue) => {
    if (newValue) {
      clientSelectedListView.value = selectedListView.value.desktop
      return
    }

    clientSelectedListView.value = selectedListView.value.mobile
  })

  onMounted(() => {
    clientSelectedListView.value = isDesktop.value ? selectedListView.value.desktop : selectedListView.value.mobile
  })

  return {
    listViewSwitches,
    selectedListView,
    clientSelectedListView,
  }
}

export { useListViewSwitches }
