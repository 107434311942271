import type { CurrentEvent, PastEvent } from './T3CeRawlplugAcademyMap.types'

/**
 * Method slightly changes coordinates of given number of objects with the same coordinates
 * It might be useful in mapbox-gl which stacks points with the same coordinates, so we
 * cannot distinguish multiple events
 * @param events
 * @param maxCoordinatesToChange
 */
export function changeDuplicateCoordinates(events: CurrentEvent[], maxCoordinatesToChange = 4) {
  const coordinateMap = new Map()

  return events.map((obj) => {
    const { lat, lon } = obj
    const coordinateKey = `${lat.toFixed(5)}_${lon.toFixed(5)}`
    const count = coordinateMap.get(coordinateKey) || 0

    if (count < maxCoordinatesToChange) {
      coordinateMap.set(coordinateKey, count + 1) // Increment the count
      return {
        ...obj,
        lat: Number((lat + count * 0.00005).toFixed(5)),
        lon: Number((lon + count * 0.00005).toFixed(5)),
      }
    }
    else {
      return obj
    }
  })
}

/**
 * The method looks for objects with identical coordinates, groups them and counts their occurances, then
 * it compares `dateFrom` with the current date.
 * If there are more objects with the same coordinates, only the one where `dateFrom` is closest to
 * the current date is returned.
 * @param events
 */
export function reduceToClosestDateByCoordinates(events: PastEvent[]) {
  const results = []

  const groupedObjects: {
    [key: string]: {
      count: number
      objects: PastEvent[]
    }
  } = {}

  // Group objects by lat and lon
  for (const obj of events) {
    const key = `${obj.lat}_${obj.lon}`
    if (!groupedObjects[key]) {
      groupedObjects[key] = {
        count: 0,
        objects: [],
      }
    }
    groupedObjects[key].count++
    groupedObjects[key].objects.push(obj)
  }

  for (const key in groupedObjects) {
    const group = groupedObjects[key]
    if (group.count > 1) {
      // get object with `dateFrom` being closest to the current date
      group.objects.sort((a, b) => {
        const currentDate = new Date().getTime()
        const dateA = new Date(a.dateFrom.date).getTime()
        const dateB = new Date(b.dateFrom.date).getTime()

        return Math.abs(dateA - currentDate) - Math.abs(dateB - currentDate)
      })

      const objectWithCount = group.objects[0]
      objectWithCount.count = group.count
      results.push(objectWithCount)
    }
    else {
      results.push(...group.objects)
    }
  }

  return results
}
