import type { SelectableListItem } from './UiNestedSelectableList.types'

function recursiveForEach(item: SelectableListItem<any>, callback: (item: SelectableListItem<any>) => unknown) {
  if (!item.children) { return }

  item.children.forEach((child) => {
    callback(child)
    recursiveForEach(child, callback)
  })
}

export function useNestedSelectableList(selectedKeys: Ref<Set<any>>) {
  const toggleRowSelection = (item: SelectableListItem<unknown>) => {
    const handleSelectMutation = (key: unknown) => {
      if (selectedKeys.value.has(item.key)) {
        selectedKeys.value.delete(key)
      }
      else {
        selectedKeys.value.add(key)
      }
    }

    recursiveForEach(item, (iteratedItem) => {
      handleSelectMutation(iteratedItem.key)
    })

    handleSelectMutation(item.key)
  }

  const synchronizeStateToChildren = (
    parentItem: SelectableListItem<unknown>,
  ) => {
    const isEveryChildSelected = parentItem.children?.every((child) => {
      return selectedKeys.value.has(child.key)
    })

    if (!isEveryChildSelected) {
      selectedKeys.value.delete(parentItem.key)
      return false
    }

    selectedKeys.value.add(parentItem.key)

    return true
  }

  return { toggleRowSelection, synchronizeStateToChildren }
}
