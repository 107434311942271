import { storeToRefs } from 'pinia'
import usePromotionsStore from '../stores/promotions'

function usePromotions() {
  const promotionsStore = usePromotionsStore()
  const { promotions } = storeToRefs(promotionsStore)
  const { setPromotions } = promotionsStore

  return {
    promotions,
    setPromotions,
  }
}

export default usePromotions
