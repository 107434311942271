import type { GetColumnOrColumnPropValue } from './UiBaseTable.types'

// Function returns column property value if propnName is provided. If not, whole column object is returned
export const getColumnOrColumnPropValue: GetColumnOrColumnPropValue = (
  columns,
  columnName,
  propName,
) => {
  if (!columns || !Array.isArray(columns) || !columnName) {
    return
  }
  for (const column of columns) {
    const columnValue = column.value
    if (Array.isArray(columnValue) && columnValue.length) {
      for (const item of columnValue) {
        if (item.name === columnName) {
          if (propName !== undefined) {
            return item[propName]
          }
          else {
            return item
          }
        }
      }
    }
  }
}
