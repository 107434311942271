import { defineStore } from 'pinia'
import type {
  Notification,
  NotificationId,
} from '@ui/composables/useNotificationsUi'
import type { NotificationComponentType } from './notifications.types'
import { NotificationComponentType as NotificationComponentTypeValue } from './notifications.types'

export const useNotificationsStore = defineStore('notifications', () => {
  const systemNotifications = ref<Notification[]>([])
  const toastMessages = ref<Notification[]>([])
  const notificationComponentTypes = Object.values(
    NotificationComponentTypeValue,
  )

  const showNotification = (
    notificationObject: Notification,
    componentType: NotificationComponentType,
  ) => {
    if (isNotificationVisible(notificationObject)) {
      return
    }

    componentType === NotificationComponentTypeValue.SYSTEM
      ? systemNotifications.value.unshift(notificationObject)
      : toastMessages.value.unshift(notificationObject)
  }

  const isNotificationVisible = (notificationObject: Notification) => {
    return [...systemNotifications.value, ...toastMessages.value].find(
      ({ id }) => id === notificationObject.id,
    )
  }

  const closeNotification = (
    id: NotificationId,
    componentType: NotificationComponentType,
  ) => {
    switch (componentType) {
      case NotificationComponentTypeValue.SYSTEM:
        {
          const notifications = systemNotifications.value
          const notificationIndex = notifications.findIndex(
            (message: Notification) => message.id === id,
          )
          if (notificationIndex !== -1) {
            notifications.splice(notificationIndex, 1)
          }
        }
        break
      default:
        {
          const messages = toastMessages.value
          const messageIndex = messages.findIndex(
            (message: Notification) => message.id === id,
          )
          if (messageIndex !== -1) {
            messages.splice(messageIndex, 1)
          }
        }
        break
    }
  }

  const clearToastMessages = () => {
    toastMessages.value.length = 0
  }

  const clearSystemNotifications = () => {
    systemNotifications.value.length = 0
  }

  return {
    systemNotifications,
    toastMessages,
    notificationComponentTypes,
    showNotification,
    closeNotification,
    clearToastMessages,
    clearSystemNotifications,
  }
})
