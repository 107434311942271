/**
 * Fix iOS input zoom
 * Must be enabled both client side and SSR, because of nuxt defaults for viewport meta.
 * https://nuxt.com/docs/getting-started/seo-meta#defaults
 *
 * maximum-scale=1 is safe to use - iOS ignores that (allow user to zoom, but still resolves the zoom issue)
 */
export default defineNuxtPlugin(() => {
  let userAgent = ''
  if (import.meta.server) {
    const headers = useRequestHeaders()
    userAgent = headers['user-agent'] || ''
  }
  else {
    userAgent = navigator.userAgent
  }

  const regExpList = {
    ios: /iPad|iPhone|iPod/,
    safari: /safari|applewebkit/i,
  }

  let viewportMetaContent
    = 'width=device-width, initial-scale=1, viewport-fit=cover'

  if (regExpList.ios.test(userAgent) && regExpList.safari.test(userAgent)) {
    viewportMetaContent += ', maximum-scale=1'
  }

  useHead({
    meta: [
      {
        name: 'viewport',
        content: viewportMetaContent,
      },
    ],
  })
})
