<template>
  <div v-if="fullScreenError">
    <div v-if="debug">
      <pre class="whitespace-pre-wrap">{{ error }}</pre>
    </div>
    <ErrorPage
      :error-code="props.error.statusCode"
      :error-message="errorMessage"
      :error-image="errorImage"
      :background="errorBackground"
      :error-messsage-description="errorMessageDescription"
      :show-logo="true"
    >
      <template #buttons>
        <UiButton
          v-if="$props.error.statusCode === 500"
          class="place-self-center"
          onclick="window.location.reload()"
        >
          {{ t('refresh') }}
        </UiButton>
        <UiButton
          :to="homePageUrl"
          class="place-self-center"
          @click.prevent="forceGoToHomePage"
        >
          {{ t('home_page') }}
        </UiButton>
      </template>
    </ErrorPage>
  </div>
  <NuxtLayout v-else>
    <ErrorPage
      :error-code="props.error.statusCode"
      :error-message="errorMessage"
      :error-image="errorImage"
      :background="errorBackground"
      :error-messsage-description="errorMessageDescription"
    >
      <template #buttons>
        <UiButton
          :to="homePageUrl"
          class="place-self-center"
          @click.prevent="forceGoToHomePage"
        >
          {{ t('home_page') }}
        </UiButton>
      </template>
    </ErrorPage>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { H3Error } from 'h3'
import type { BackgroundImages, ErrorImage } from './components/ErrorPage.vue'
import useStorefrontData from './composables/useStorefrontData'

const props = defineProps<{
  error: H3Error
}>()

useHead({
  titleTemplate: (title) => {
    return title ? `${title} - Rawlplug` : 'Rawlplug'
  },
  title: String(props.error.statusCode),
})

const { t, setLocale } = useI18n()
const { debug } = useRoute().query
const { initialData } = useT3Api()
const { currentLocale } = useT3i18n()

await setLocale(currentLocale.value)

const isInitialData = computed(() => {
  return !(
    typeof initialData.value === 'undefined'
    || (initialData.value?.statusCode && initialData.value.statusCode !== 200)
  )
})

/**
 * we can show header & footer only if initialData is available
 */
const fullScreenError = computed((): boolean => {
  if (props.error.statusCode === 404 && isInitialData.value === false) {
    return true
  }
  return props.error.statusCode !== 404 || !isDefinedStorefront.value
})

/**
 * For 404 and 500 errors we have predefined images.
 */
const errorImage = computed((): ErrorImage | undefined => {
  if (props.error.statusCode === 404) {
    return {
      src: publicDir('/error/404.svg'),
      width: 405,
      height: 178,
    }
  }
  if (props.error.statusCode === 500) {
    return {
      src: publicDir('/error/500.svg'),
      width: 405,
      height: 178,
    }
  }
  return undefined
})

const errorBackground = computed((): BackgroundImages => {
  if (props.error.statusCode === 404) {
    return {
      desktop: {
        src: publicDir('/error/404-desktop.jpg'),
        width: 3840,
        height: 1648,
      },
      mobile: {
        src: publicDir('/error/404-mobile.jpg'),
        width: 1920,
        height: 1080,
      },
    }
  }
  return {
    desktop: {
      src: publicDir('/error/500-desktop.jpg'),
      width: 1920,
      height: 1080,
    },
    mobile: {
      src: publicDir('/error/500-mobile.jpg'),
      width: 1920,
      height: 1080,
    },
  }
})

/**
 * For 404 and 500 errors we have predefined messages.
 */
const errorMessage = computed((): string => {
  if (props.error.statusCode === 404) {
    return t('we_couldnt_find_the_page_you_are_looking_for')
  }
  if (props.error.statusCode === 500) {
    return t('internal_server_error')
  }
  return (
    props.error.statusMessage
    ?? t('something_went_wrong')
  )
})

const errorMessageDescription = computed((): string | undefined => {
  if (props.error.statusCode === 500) {
    return t('the_server_encountered_an_unexpected_condition_which_prevented_it_from_fulfilling_the_request')
  }
  return undefined
})

const { isDefinedStorefront } = useStorefrontData()
const homePageUrl = computed((): string => {
  if (!isDefinedStorefront.value) {
    return '/'
  }

  try {
    const { localePath } = useT3Utils()
    return localePath()
  }
  catch {
    return '/'
  }
})

/**
 * This was created because:
 * - by hard reload we will download all assets (just to make sure that we have the latest version client-side)
 * - by default on redirect to home page, refresh of TYPO3 initialData doesn't work and header & footer is missing
 */
function forceGoToHomePage() {
  window.location.href = homePageUrl.value
}
</script>
