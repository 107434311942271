import type { Row } from '@ui/components/UiBaseTable/UiBaseTable.types'
import { useCustomerOrderProductData } from '@ecom/composables/customer/useCustomerOrderProductData/useCustomerOrderProductData'
import type { MappedOrderItem } from '@ecom/types/checkout.types'
import { ProductType } from '@ecom/types/product.types'
import type { OrderItemInterface } from '#gql'

const ImageDefaults = {
  brand: {
    height: 20,
  },
  thumbnail: {
    width: 62,
    height: 66,
  },
}

// Helper function mapping order items and returning array of items
export function mapOrderItems(items: OrderItemInterface[]): MappedOrderItem[] {
  const { getOrderItem } = useCustomerOrderProductData()

  if (!Array.isArray(items) || !items?.length) {
    return []
  }

  return items.map((item) => {
    const {
      name,
      availabilityStatus,
      sku,
      barcode,
      thumbnail,
      link,
      brand,
      quantity,
      packaging,
      netPriceWithDiscount,
      totalRowNetPrice,
    } = getOrderItem(item)

    return {
      name,
      availabilityStatus,
      link,
      brand: {
        src: brand.src,
        alt: brand.alt,
        height: ImageDefaults.brand.height,
      },
      sku,
      barcode,
      image: {
        src: thumbnail,
        alt: name,
        width: ImageDefaults.thumbnail.width,
        height: ImageDefaults.thumbnail.height,
      },
      quantity,
      packing: {
        image: packaging?.image ?? null,
        description: packaging?.label ?? '',
      },
      price: {
        netPriceWithDiscount,
        totalNetPrice: totalRowNetPrice,
      },
    }
  })
}

// cretes table data used in order summary and history
export function createOrderItemsTableRows(orderItems: MappedOrderItem[]) {
  if (!Array.isArray(orderItems) || !orderItems?.length) {
    return []
  }

  const rows: Row[] = []
  orderItems?.forEach((item, index: number) => {
    rows.push({
      name: item.name ?? '',
      item,
      value: [
        {
          name: 'lp',
          value: index + 1,
        },
        {
          name: 'product',
          value: item.name ?? '',
        },
        {
          name: 'amountOfPackages',
          value: item.quantity,
        },
        {
          name: 'packingType',
          value: item.packing,
        },
        {
          name: 'netPriceForPackage',
          value: item.price.netPriceWithDiscount,
        },
        {
          name: 'totalNetPrice',
          value: item.price.totalNetPrice,
        },
      ],
    })
  })
  return rows
}

// Methods used to split order items into three arrays - available, not available and virtual products
export function splitOrderProductsByAvailabilityAndType(items: OrderItemInterface[] | null) {
  if (!Array.isArray(items) || !items?.length) {
    return {
      available: [],
      notAvailable: [],
      virtual: [],
    }
  }

  /* If there is no availability_status field on order item, set it by default to true
    There might be some cases where past orders will not have this field, and because of
    that we cannot set them as unavailable
    */
  const available
    = items?.filter(item => (item?.availability_status ?? true) && item?.product?.product_typename !== ProductType.VIRTUAL) ?? []

  const notAvailable
    = items?.filter(item => !(item?.availability_status ?? true) && item?.product?.product_typename !== ProductType.VIRTUAL) ?? []

  const virtual
    = items?.filter(item => item?.product?.product_typename === ProductType.VIRTUAL) ?? []

  return {
    available,
    notAvailable,
    virtual,
  }
}

export function hasAvailableInOrderProducts(items?: OrderItemInterface[]) {
  return items?.some(product => product.availability_status) || false
}

export function hasNotAvailableInOrderProducts(items?: OrderItemInterface[]) {
  return items?.some(product => !product.availability_status) || false
}
