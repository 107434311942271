import { defineStore } from 'pinia'
import type { PromotionItem } from '@cms/types/MagentoPage.types'

export interface PromotionsStore {
  promotions: PromotionItem[]
}

/**
 * Store for promotions data from TYPO3 for Magento pages (category and product promotions)
 */
export default defineStore('promotions', {
  state: (): PromotionsStore => ({
    promotions: [],
  }),
  actions: {
    setPromotions(promotions: PromotionItem[]) {
      this.promotions = [] // reset promotions
      if (Array.isArray(promotions)) {
        this.promotions = promotions
      }
    },
  },
})
