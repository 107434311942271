import { defineRule } from 'vee-validate'
import { regex, required } from '@vee-validate/rules'

export default defineNuxtPlugin({
  name: 'vee-validate-ecommerce',
  parallel: true,
  async setup({ $i18n }) {
    const { t } = $i18n

    // Magento 2 compatible email rule
    defineRule('email', (value: string) => {
      if (!value || !value.length) {
        return true
      }

      // https://html.spec.whatwg.org/#email-state-(type=email)
      // eslint-disable-next-line regexp/prefer-w, regexp/no-useless-escape, regexp/use-ignore-case
      const whatwgRecommendedEmailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      if (!whatwgRecommendedEmailRegex.test(value)) {
        return t('validation.email')
      }

      return true
    })

    // Magento 2 compatible password rule
    defineRule('password', (value: string) => {
      if (!value || !value.length) {
        return true
      }

      // regex rules disabled, to maintain 1:1 rule syntax with Magento 2
      // eslint-disable-next-line regexp/no-empty-lookarounds-assertion, regexp/no-unused-capturing-group, regexp/prefer-plus-quantifier
      const passwordRegex = /^(?=(.*?[a-z]){1,})(?=(.*?[A-Z]){1,})(?=(.*?\d){1,}|)(?=(.*?[!@#$%^&*()_+~`\-={}[\]:;"'<>,.?/]){1,}|)(?!.*\s).{8,}$/
      if (!passwordRegex.test(value)) {
        return t('validation.password')
      }

      return true
    })

    defineRule('nipPoland', (value: string) => {
      const validationErrorMessage = t('validation.tax_number')

      const nipWithoutDashes = value.replace(/-/gu, ``)
      const reg = /^\d{10}$/u
      if (!reg.test(nipWithoutDashes)) {
        return validationErrorMessage
      }

      const dig = String(nipWithoutDashes).split(``)
      const controlValues = [6, 5, 7, 2, 3, 4, 5, 6, 7]
      const partialSums = controlValues.map((controlValue, index) => {
        return controlValue * Number.parseInt(dig[index], 10)
      })

      let sum = 0
      partialSums.forEach((partialSum) => {
        sum += partialSum
      })

      const control = sum % 11

      if (Number.parseInt(dig[9], 10) !== control) {
        return validationErrorMessage
      }

      return true
    })

    defineRule('oneOfFields', (_value, target, ctx) => {
      const some = target.some((field: number) => ctx.form[field] === true)
      if (!some) {
        return t('validation.one_of_fields')
      }
      return true
    })

    defineRule('required-delivery-address', (value: unknown): boolean | string => {
      if (!required(value)) {
        return t('validation.delivery_address_must_be_selected')
      }
      return true
    })

    defineRule('required-payment-method', (value: unknown): boolean | string => {
      if (!required(value)) {
        return t('validation.payment_method_must_be_selected')
      }
      return true
    })

    defineRule('required-shipping-method', (value: unknown): boolean | string => {
      if (!required(value)) {
        return t('validation.shipping_method_must_be_selected')
      }
      return true
    })

    defineRule('postcode-format', (value: unknown, params: [string | RegExp] | { regex: RegExp | string }): boolean => {
      if (!regex(value, params)) {
        return t('validation.invalid_postcode_format')
      }
      return true
    })
  },
})
