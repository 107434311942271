/**
 * This plugin is responsible for parsing image objects from Magento 2 and TYPO3 API,
 * and then returns attributes required to use in NuxtImg (@nuxt/image).
 */

import type { T3File, T3Gallery } from '@t3headless/nuxt-typo3'
import type { ImageInterface } from '@ui/components/UiImage/UiImage.types'

export interface ImageDimensions {
  width: number
  height: number
}
export interface ImageMagento2Interface {
  // we should map size values from view.xml
  url: string
  small_ur: string
  medium_url: string
  large_url: string
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      /**
       * Rturns first element from TYPO3 Gallery, File or Content object
       */
      getMediaData: (media: T3Gallery | T3File): T3File | null => {
        // If TYPO3 Gallery
        if ('rows' in media) {
          // yes, we look for '1' element, because we are looking in object with iterable keys (1, 2, 3, ...)
          return media?.rows?.[1]?.columns?.[1] ?? null
        }

        // If TYPO3 Content
        if ('content' in media) {
          return media?.content?.file?.[0] ?? null
        }

        // If TYPO3 File
        if ('properties' in media) {
          return media
        }

        return null
      },
      /**
       * Returns image properties for NuxtImg component
       */
      getImgProps: (image: T3File | ImageMagento2Interface) => {
        if (!image) {
          return null
        }

        let imageData: ImageInterface = {
          src: '',
          originalWidth: 0,
          originalHeight: 0,
        }

        // IF TYPO3
        if ('properties' in image) {
          imageData = {
            src: image?.publicUrl || '',
            originalWidth: image?.properties?.dimensions?.width || 0,
            originalHeight: image?.properties?.dimensions?.height || 0,
            alt: image?.properties?.alternative || '',
            title: image?.properties?.title || null,
          }
        }

        // IF MAGENTO2
        if ('image' in image) {
          imageData = {
            src: image.image.url,
            originalWidth: 600, // TODO: receive image size from GraphQL API
            originalHeight: 600,
          }
        }

        return imageData
      },
    },
  }
})
