<template>
  <DelayHydration>
    <LazyAppToastMessages />
  </DelayHydration>
  <VitePwaManifest />
  <NuxtLayout>
    <NuxtLoadingIndicator
      color="#FFF"
      :style="{ backfaceVisibility: 'hidden' }"
    />
    <NuxtPage />
  </NuxtLayout>
  <DelayHydration>
    <ClientOnly>
      <LazyLoadingOverlay />
    </ClientOnly>
  </DelayHydration>
</template>

<script setup lang="ts">
import type { Link } from '@unhead/vue'
import { withLeadingSlash } from 'ufo'
import useConditionalRender from './composables/useConditionalRender/useConditionalRender'
import { DelayHydration, LazyAppToastMessages, NuxtLoadingIndicator, VitePwaManifest } from '#components'

const { initialData } = useT3Api()
const { currentLocale } = useT3i18n()
const { setLocale } = useI18n()
const { hook, $config, $setHeaders, runWithContext } = useNuxtApp()

/**
 * Fetch store config
 */
const { isEcommerceEnabled } = useConditionalRender()
if (isEcommerceEnabled.value) {
  await import('../rawlplug-ecom/composables/useStoreConfig/useStoreConfig').then(({ useStoreConfig }) => {
    runWithContext(() => {
      const { getStoreConfig } = useStoreConfig()
      getStoreConfig()

      // set Magento headers
      if (typeof $setHeaders === 'function') {
        $setHeaders()
      }
    })
  })
}

const socialMediaLinks = computed(() => {
  const socialMedia = initialData.value?.social || []
  return socialMedia.map(item => item?.link).filter(item => item)
})

const imgproxyDnsPrefetch = computed(() => {
  const host = $config?.public?.imgproxy
  if (host) {
    return {
      rel: 'preconnect',
      href: host,
    }
  }
  return undefined
})

/**
 * Set meta tags
 */
useHead({
  templateParams: {
    schemaOrg: {
      host: $config.public.siteUrl + withLeadingSlash($config.public.app.region),
    },
  },
  titleTemplate: (title) => {
    return title ? `${title} - Rawlplug` : 'Rawlplug'
  },
  link: [
    imgproxyDnsPrefetch as Link,
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: publicDir('/icons/apple-touch-icon.png'),
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: publicDir('/icons/favicon-32x32.png'),
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: publicDir('/icons/favicon-16x16.png'),
    },
    {
      rel: 'preload',
      href: publicDir('/fonts/ubuntu-v20-latin-ext_latin-regular.woff2'),
      as: 'font',
      type: 'font/woff2',
      crossorigin: 'anonymous',
    },
    {
      rel: 'preload',
      href: publicDir('/fonts/ubuntu-v20-latin-ext_latin-700.woff2'),
      as: 'font',
      type: 'font/woff2',
      crossorigin: 'anonymous',
    },
  ],
  meta: [
    { name: 'theme-color', content: '#1C5188' },
  ],
  style: [
    `@font-face {
        font-family: 'Adjusted Verdana Fallback';
        src: local(Verdana);
        size-adjust: 90%;
        ascent-override: 103%;
        descent-override: normal;
        line-gap-override: normal;
      }
      @font-face {
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
            url('${publicDir('/fonts/ubuntu-v20-latin-ext_latin-regular.woff2')}') format('woff2'),
            url('${publicDir('/fonts/ubuntu-v20-latin-ext_latin-regular.woff')}') format('woff');
      }
      @font-face {
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
            url('${publicDir('/fonts/ubuntu-v20-latin-ext_latin-700.woff2')}') format('woff2'),
            url('${publicDir('/fonts/ubuntu-v20-latin-ext_latin-700.woff')}') format('woff');
      }
      @font-face {
      font-family: 'Ubuntu';
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
          url('${publicDir('/fonts/ubuntu-v20-latin-ext_latin-500.woff2')}') format('woff2'),
          url('${publicDir('/fonts/ubuntu-v20-latin-ext_latin-500.woff')}') format('woff');
    }
    @font-face {
      font-family: 'Ubuntu';
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: local('Ubuntu Light'), local('Ubuntu-Light'),
          url('${publicDir('/fonts/ubuntu-v20-latin_latin-ext-300.woff2')}') format('woff2'),
          url('${publicDir('/fonts/ubuntu-v20-latin_latin-ext-300.woff')}') format('woff');
    }
    `,
  ],
})

useSchemaOrg([
  defineOrganization({
    '@id': '#organization',
    'name': 'Rawlplug',
    'logo': publicDir('/logo.svg'),
    'sameAs': socialMediaLinks.value,
  }),
  defineWebSite({
    name: 'Rawlplug',
    inLanguage: currentLocale.value,
  }),
  defineWebPage(),
])

await setLocale(currentLocale.value)
hook('t3:i18n', async (newLocale) => {
  await setLocale(newLocale)
})
</script>
