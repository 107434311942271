function spinGlobe(secondsPerRevolution: number) {
  const distancePerSecond = 360 / secondsPerRevolution

  return (map: mapboxgl.Map) => {
    const move = () => {
      // Smoothly animate the map over one second.
      // When this animation is complete, it calls a 'moveend' event.
      const center = map.getCenter()
      center.lng -= distancePerSecond
      map.easeTo({ center, duration: 1000, easing: n => n })
    }

    map.on('moveend', move)
    move()
  }
}

export default spinGlobe
