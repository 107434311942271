import { useCustomerCreditHold } from '../composables/customer/useCustomerCreditHold/useCustomerCreditHold'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    toggleSystemNotificationOnCreditHold,
    toggleToastNotificationOnCreditHold,
  } = useCustomerCreditHold()

  nuxtApp.hook('customer:login', () => {
    toggleSystemNotificationOnCreditHold()
  })

  nuxtApp.hook('customer:logout', () => {
    toggleSystemNotificationOnCreditHold()
  })

  nuxtApp.hook('ecom:cart-add', () => {
    toggleToastNotificationOnCreditHold()
  })
})
