import { useNotification } from '@base/composables/useNotifications'
import type { NotificationComponentType } from '@base/stores/notifications.types'
import type { GqlError } from 'nuxt-graphql-client'

export enum GraphqlErrorId {
  // User important errors have 'graphql-input' category (not everytime due to inconsistency of Magento 2)
  INPUT = 'graphql-input',
  // Messages not important for the regular user
  GRAPHQL = 'graphql',
  AUTHORIZATION = 'graphql-authorization',
  NEWSLETTER = 'graphql-already-exists',
}

export enum GraphQlExcludedOperationName {
  RESET_PASSWORD = 'passwordReset',
  AUTHENTICATION = 'changePassword',
  ADD_TO_CART = 'addToCart',
}

/**
 * Plugin logs to the console the most important graphql error information and displays
 * toast message for each error, except for the excluded operations specified in
 * 'GraphQlExcludedOperationName' enum
 *
 * If GraphQl category is 'graphql-input', thrown error message will be displayed,
 * otherwise 'Something wend wrong' will show up
 */
export default defineNuxtPlugin((nuxtApp) => {
  const { $i18n, callHook } = nuxtApp
  const { t } = $i18n
  const addToCartNotificationType = 'toast' as NotificationComponentType
  const { showNotification } = useNotification(addToCartNotificationType)

  const showErrorNotification = ({ message }: { message: string }) => {
    showNotification({
      id: crypto.randomUUID(),
      type: 'error',
      message,
      closable: true,
      timeout: 5000,
    })
  }

  useGqlError((err: GqlError) => {
    callHook('ecom:graphql:onError', err)

    for (const gqlError of err.gqlErrors) {
      console.error('[nuxt-graphql-client] [GraphQL error]', {
        client: err.client,
        statusCode: err.statusCode,
        operationType: err.operationType,
        operationName: err.operationName,
        gqlError,
      })

      const { message = '', extensions: { category = '' } = { category: '' } }
        = gqlError

      if (
        message
        && !Object.values(GraphQlExcludedOperationName).includes(err.operationName as GraphQlExcludedOperationName)
      ) {
        if (
          (category
          && (category === GraphqlErrorId.INPUT
          || category === GraphqlErrorId.AUTHORIZATION
          || category === GraphqlErrorId.NEWSLETTER)) || err.operationName === 'applyCouponToCart'
        ) {
          showErrorNotification({
            message,
          })
        }
        else {
          showErrorNotification({
            message: t('something_went_wrong'),
          })
        }
      }
    }
  })
})
