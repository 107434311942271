import type { PublicRuntimeConfig } from 'nuxt/schema'
import { joinURL, withLeadingSlash } from 'ufo'

interface RawlplugConsentVariablesValues {
  privacyPolicyUrl: string
  termsUrl: string
}
interface RawlplugConsentVariables {
  [key: string]: RawlplugConsentVariablesValues
}
type AvailableLocales = keyof PublicRuntimeConfig['i18n']['locales']

const CONSENTS_VARIABLES: RawlplugConsentVariables = {
  pl: {
    privacyPolicyUrl: '/pl/polityka-prywatnosci',
    termsUrl: '/pl/regulamin-sklepu',
  },
  en: {
    privacyPolicyUrl: '/en/privacy-policy',
    termsUrl: '/en/rawlplug-general-terms-and-conditions-of-sale',
  },
}

/**
 * Replace variables in consents description and moreDescription
 * @param string - string to replace variables
 * @returns string with replaced variables
 * @todo: consents variables should be stored in CMS initialData - remove this in favor of usePlaceholders composable
 */
function replaceConsentVariables(string: string) {
  const { currentLocale } = useT3i18n()

  /**
   * Extract variables from string and replace with real values.
   * @example "Lorem ipsum <a href="[privacyPolicyUrl]">Privacy Policy</a> dolor sit amet, consectetur adipiscing elit."
   */
  const result = string.replace(/\[(.*?)\]/g, (_match, capture) => {
    return (
      getConsentVariable(currentLocale.value as AvailableLocales, capture)
      ?? capture
    )
  })
  return result
}

function getConsentVariable(lang: AvailableLocales, link: keyof RawlplugConsentVariablesValues) {
  const runtimeConfig = useRuntimeConfig()
  const region = runtimeConfig.public.app.region
  return withLeadingSlash(joinURL(region, CONSENTS_VARIABLES?.[lang]?.[link]))
}

export { getConsentVariable }
export default replaceConsentVariables
