import type { CategoryList } from './UiSideNestedMenu.types'

export function getOutermostParentNode(node: CategoryList | CategoryList[], urlPath: string): string[] | void {
  if (!Array.isArray(node) && node?.canonical_url === urlPath) {
    return [node.uid]
  }

  const children = Array.isArray(node) ? node : node?.children

  if (children) {
    for (const child of children) {
      const result = getOutermostParentNode(child, urlPath)
      if (result) {
        if (!Array.isArray(node) && node.canonical_url) {
          result.unshift(node.uid)
        }
        return result
      }
    }
  }
}
