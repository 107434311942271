export default defineNuxtPlugin({
  name: 'nuxt:chunk-reload-error',
  setup(nuxtApp) {
    /**
     * We have to add custom support for chunkError
     * Nuxt handle only chunks error during route navigation
     */
    nuxtApp.hook('app:chunkError', ({ error }) => {
      if (
        error
        && error.message
        && error.message.includes('Failed to fetch dynamically imported module')
        && error.message.includes('_nuxt')
      ) {
        reloadNuxtApp({ persistState: true })
      }
    })
  },
})
